define([
    'positrace'
], function (Positrace) {

    /*
     *@example:
     *
     *  class SomeModel extends Positrace.Model
     *
     *    defaults:
     *      oldPassword: ''
     *      newPassword: ''
     *      repeatPassword: ''
     *
     *    validator:
     *      oldPassword:
     *        require: true
     *      newPassword:
     *        require: true
     *      repeatPassword:
     *        require: true
     *        validator: (value, attrs) ->
     *          unless value == attrs.newPassword
     *            return _.t 'New password and repeat password are not equal'
     *
     *    validate: (attrs, options) ->
     *      Base.model.Validator
     *      .create(@)
     *      .validate(attrs, options)
     *      .getErrors()
     */
    var Validator = Positrace.Object.extend({

        initialize: function () {
            this.rules = this.getOption('rules') || {};
            return this.errors = {};
        },

        requireValidator: function (value, attrs, rule, options) {
            var paramName = rule.paramName || '';
            if (!value || (_.isArray(value) && !value.length)) {
                return _.t("{{instance}} can't be blank", {instance: paramName});
                /*
                return _.t('This field is required');
                */
            }
        },

        lengthValidator: function (value, attrs, rule, options) {
            var allowEmpty, max, min, message;
            var paramName = rule.paramName || '';
            max = rule.length.max || 255;
            min = rule.length.min || 0;
            allowEmpty = !!rule.length.allowEmpty;
            if (_.isNumber(rule.length)) {
                max = rule.length;
            }
            if (allowEmpty && (value === '' || value === null || value === undefined)) {
                return;
            }
            if (value.length < min) {
                return _.t("{{instance}} is too short (minimum is {{count}} characters)", {
                    instance: paramName,
                    count: min
                });
                /*
                return _.t("Is too short (minimum is {{min}} characters)", {
                    min: min
                });
                */
            }
            if (value.length > max) {
                return _.t("{{instance}} is too long (maximum is {{count}} characters)", {
                    instance: paramName,
                    count: max
                });
                /*
                return _.t('Is too long (maximum is {{max}} characters)', {
                    max: max
                });
                */
            }
        },

        emailValidator: function (value, attrs, rule, options) {
            var paramName = rule.paramName || '';
            if (!Base.isValidEmail(value)) {
                return _.t("{{instance}} is invalid", {instance: paramName});
            }
        },

        executeRule: function (attrs, field, rule, options) {
            var error, value, _ref;
            // TODO: review. There are errors in DriverModel without apollo feature
            // if (!_.has(attrs, field)) {
            //     throw new Error("Unknown validator field " + field);
            // }
            value = attrs[field];
            error = void 0;
            if (rule.require) {
                error = this.requireValidator(value, attrs, rule, options);
            }
            if (!error && rule.length) {
                error = this.lengthValidator(value, attrs, rule, options);
            }
            if (!error && rule.isEmail) {
                error = this.emailValidator(value, attrs, rule, options);
            }
            if (!error && (((_ref = rule.validator) != null ? _ref.call : void 0) != null)) {
                error = rule.validator(value, attrs, rule, options);
            }
            return error;
        },

        validate: function (attrs, options) {
            _.each(this.rules, _.bind(function (rule, fieldName) {
                var error = this.executeRule(attrs, fieldName, rule, options);
                if (error) {
                    return this.setError(fieldName, error);
                }
            }, this));
            return this;
        },

        setError: function (key, message) {
            this.errors[key] = message;
            return this;
        },

        hasErrors: function () {
            return !!_.keys(this.errors).length;
        },

        getErrors: function () {
            if (this.hasErrors()) {
                return this.errors;
            }
        }

    });

    Validator.create = function (model) {
        var rules;
        rules = _.result(model, 'validator');
        return new Validator({
            rules: rules
        });
    };

    return Validator;

});
