define([
    'positrace'
], function (Positrace) {

    var Backbone = require('backbone');

    var Router = Positrace.Router.extend({

        routes: {
            "auth(/*path)": 'auth',
            '*notFound': 'index'
        },

        page404: function () {
            return this.app.navigate('auth/check', {
                trigger: true,
                replace: true
            });
        },

        index: function () {
            window.location.href = "#auth/login";
            this.auth();
        },

        loadModule: function (name, options) {
            var key, loader;
            key = "_" + name;
            if (this[key]) {
                return;
            }
            loader = this.app.request('module');
            return this[key] = loader
                .get(name, options)
                .done(function (module) {
                    return module.start();
                })
                .fail(_.bind(function (e) {
                    return this.app.execute('message:error', e.message);
                }, this));
        },

        auth: function () {
            return this.loadModule('auth');
        },

        start: function (startUrl) {
            Backbone.history.start();
            if (_.isString(startUrl)) {
                this.app.navigate(startUrl);
            }
            Positrace.Router.prototype.start.apply(this, arguments);
        }

    });

    return Router;

});