/** This module is used to temporarily override some methods in Positrace-SDK */
define(['../../../node_modules/positrace'], function (Positrace) {

    _.templateSettings = {
        interpolate: /\{\{(.+?)\}\}/g,
        evaluate: /\{\|(.+?)\|\}/g,
        escape: /\{\=(.+?)\}/g
    };

    _.t = Positrace.T;

    _.tc = function (key, values = {}, ns) {
        let n = values.count;
        let _key = key;
        if (_.isNumber(n)) {
            switch (LocateApp.langService.getLangAbbr()) {
                case 'en':
                case 'es': {
                    let plural = (n !== 1);
                    _key += plural ? "_plural" : "";
                    break;
                }
                case 'fr': {
                    let plural = (n > 1);
                    _key += plural ? "_plural" : "";
                    break;
                }
                case 'ru':
                case 'uk': {
                    let plural = (
                        n % 10 === 1 && n % 100 !== 11 ?
                            0 :
                            n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ?
                                1 :
                                2
                    );
                    _key += plural ? `_${plural}` : "";
                    break;
                }
            }
        }
        return _.t(_key, values, ns);
    };

    Positrace.locate.request.xhrPool = [];

    Positrace.locate.request.abortAll = function() {
        _.each(this.xhrPool, function(jqXHR) {
            if (typeof jqXHR !== "undefined" && jqXHR !== null) {
                if (typeof jqXHR.abort === "function") {
                    jqXHR.abort();
                }
            }
        });
        this.xhrPool.length = 0;
    };

    //Overriding method ajax to use contentType 'application/json' and 'crossDomain: false'
    Positrace.locate.request.ajax = function (params, options) {
        if (params == null) {
            params = {};
        }
        if (options == null) {
            options = {};
        }
        _.defaults(params, {
            xhrFields: {
                withCredentials: true
            },
            headers: this.extraHeaders,
            crossDomain: false, //OVERRIDDEN
            converters: {
                "text json": (function (_this) {
                    return function (text) {
                        return _this.converter(text, params, options);
                    };
                })(this)
            }
        });

        //OVERRIDDEN
        (params.type === "PATCH") && (params.type = "PUT");
        params.type || (params.type = 'GET');
        if (params.type !== 'GET') {
            params.contentType = 'application/json; charset=utf-8';
            params.dataType = "json";
            params.data = JSON.stringify(params.data);
        }

        this.triggerMethod('before:request', params, options);
        var jqXHR = $.ajax(params).always((function(_this) {
            return function() {
                var index;
                index = _this.xhrPool.indexOf(jqXHR);
                if (index > -1) {
                    return _this.xhrPool.splice(index, 1);
                }
            };
        })(this)).fail((function(_this) {
            return function (xhr, status, error) {
                return _this.triggerMethod('request:error', xhr, status, error);
            };
        })(this)).done((function (_this) {
            return function (data, status, xhr) {
                return _this.triggerMethod('request:success', data, status, xhr);
            };
        })(this));

        this.xhrPool.push(jqXHR);

        return jqXHR;
    };

    var requestAddressFromGoogle_original = Positrace.locate.util.google.Geocoder.prototype.requestAddressFromGoogle;
    Positrace.locate.util.google.Geocoder.prototype.requestAddressFromGoogle = function (options) {
        var isReverseGeocoding = options.latLng || options.location;
        if (isReverseGeocoding) {
            LocateApp.analytics.sendEvent('Google Reverse Geocoder Request');
        } else {
            LocateApp.analytics.sendEvent('Google Geocoder Request');
        }
        return requestAddressFromGoogle_original.apply(this, arguments);
    };

    var requestAddressFromServer_original = Positrace.locate.util.google.Geocoder.prototype.requestAddressFromServer;
    Positrace.locate.util.google.Geocoder.prototype.requestAddressFromServer = function (options) {
        var isReverseGeocoding = options.latLng || options.location;
        if (isReverseGeocoding) {
            LocateApp.analytics.sendEvent('Locate Reverse Geocoder Request');
        } else {
            LocateApp.analytics.sendEvent('Locate Geocoder Request');
        }
        return requestAddressFromServer_original.apply(this, arguments);
    };

    Positrace.locate.collection.Paginator.prototype.getParamsURI = function () {
        var paginationState = _.map(_.pick(this, 'page', 'perPage', 'sort', 'sortBy'), function (val, key) {
            return key + '=' + encodeURIComponent(val);
        });
        if (this._data && _.any(this._data.filters)) {
            var filterState = _.map(_.pick(this._data.filters, function (value, key, object) {
                return !!value;
            }), function (val, key) {
                return 'filters[' + key + ']=' + encodeURIComponent(val);
            });
            paginationState = paginationState.concat(filterState);
        }
        return paginationState.join('&');
    };

    Positrace.locate.collection.Paginator.prototype.setData = function(_data) {
        var isEqual;
        isEqual = _.isEqual(_data, this._data);
        this._data = _data;
        if (!isEqual) {
            this.trigger('change:data');
        }
        return this;
    };

    /*
    Positrace.locate.model.DriverModel.prototype.getTimezone = function () {
        var driverAccount = this.get('account');
        return driverAccount ? driverAccount.home_terminal_timezone || '' : '';
    };
    */


    Positrace.locate.model.HomeTerminalModel.prototype.validator = {
        name: {
            require: true,
            length: {
                allowEmpty: false
            }
        }
    };

    Positrace.locate.model.HomeTerminalModel.prototype.validate = function (attrs, options) {
        this.validator = {
            name: {
                paramName: _.t("Name"),
                require: true,
                length: {
                    allowEmpty: false
                }
            },
            apollo_time_zone: {
                paramName: `Apollo ${_.t("Time Zone")}`,
                require: _.availableFeature('integration.apollo_eld')
            }
        };
        var errors = Base.model.Validator.create(this)
            .validate(attrs, options)
            .getErrors();
        return errors;
    };

    Positrace.locate.model.DriverModel.prototype.validate = function (attrs, options) {
        var _t = _.partial(_.t, _, _, 'driver');
        var isNewModel = this.isNew();
        this.validator = {
            user_attributes: {
                paramName: _t('Email'),
                validator: function (user, attrs) {
                    if (!Base.isValidEmail(user.email)) {
                        return _.t('{{instance}} is invalid', {instance: this.paramName});
                    }
                    if (isNewModel && !user.password) {
                        return _.t("Password can't be blank");
                    }
                    if ((user.password || user.password_confirmation) && user.password !== user.password_confirmation) {
                        return _.t("Password doesn't match confirmation");
                    }
                }
            },
            first_name: {
                paramName: _t("First Name"),
                require: true,
                length: {
                    allowEmpty: false
                }
            },
            last_name: {
                paramName: _t("Last Name"),
                require: true,
                length: {
                    allowEmpty: false
                }
            },
            driver_license_number: {
                paramName: _t("Driving License Number"),
                require: _.availableFeature('integration.apollo_eld'),
                validator: function (value, attrs) {
                    if (attrs.driver_license_state_id && !value) {
                        return _.t("{{instance}} can't be blank", {instance: this.paramName});
                    }
                }
            },
            driver_license_state_id: {
                paramName: _t("Driving License State"),
                require: _.availableFeature('integration.apollo_eld'),
                validator: function (value, attrs) {
                    if (attrs.driver_license_number && !value) {
                        return _.t("{{instance}} can't be blank", {instance: this.paramName});
                    }
                }
            },
            home_terminal_id: {
                paramName: _t("Home Terminal"),
                require: _.availableFeature('integration.apollo_eld')
            },
            eld_exemption_reason: {
                paramName: _t("Exemption Reason"),
                validator: function (value, attrs) {
                    if (_.availableFeature('integration.apollo_eld') && attrs.exemption) {
                        if (!_.isString(value) || !value.trim().length) {
                            return _.t("{{instance}} can't be blank", {instance: this.paramName});
                        }
                    }
                }
            },
            apollo_rule_set_id: {
                paramName: _t("Apollo Rule Set"),
                require: _.availableFeature('integration.apollo_eld')
            },
            apollo_jurisdiction_id: {
                paramName: _t("Apollo Jurisdiction"),
                validator: function (value, attrs) {
                    if (!_.availableFeature('integration.apollo_eld')) {
                        return;
                    }
                    const CANADA_RULE_SETS = [6, 7];
                    if (CANADA_RULE_SETS.includes(Number(attrs.apollo_rule_set_id)) && !value) {
                        return _.t("{{instance}} can't be blank", {instance: this.paramName});
                    }
                }
            },
            apollo_period_starting_time_id: {
                paramName: _t("24 Hours Start Time"),
                require: _.availableFeature('integration.apollo_eld')
            },
            apollo_eld_username: {
                paramName: _.t("Apollo ELD Driver Username"),
                require: _.availableFeature('integration.apollo_eld') && !this.get('apollo_eld_username'),
                length: {
                    allowEmpty: true,
                    min: 4,
                    max: 50
                }
            },
            apollo_eld_password: {
                paramName: _t("Apollo ELD Driver Password"),
                require: _.availableFeature('integration.apollo_eld') && !this.get('apollo_eld_password_set'),
                length: {
                    allowEmpty: true,
                    min: 3,
                    max: 15
                },
                validator(value, attrs) {
                    if (!value?.length && !attrs.apollo_eld_password_confirmation?.length) {
                        return undefined;
                    }

                    if (value != attrs.apollo_eld_password_confirmation) {
                        return _.t("{{instance}} doesn't match confirmation", {
                            instance: this.paramName
                        });
                    }
                }
            },
            apollo_eld_password_confirmation: {}
        };
        var errors = Base.model.Validator.create(this)
            .validate(attrs, options)
            .getErrors();
        return errors;
    };

    Positrace.locate.model.EquipmentAlarmModel.prototype.validate = function (attrs, options) {
        this.validator = {
            deactivation_comment: {
                paramName: _.t("Comment"),
                require: true,
                length: {
                    allowEmpty: false,
                    min: 5
                }
            }
        };
        let errors = Base.model.Validator.create(this).validate(attrs, options).getErrors();
        return errors;
    };

    window.Positrace = Positrace;//HACK! TODO: remove with UJS

    return Positrace;

});
