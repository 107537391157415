define([
    'lib/custom/moment.custom',
    'amdi18n-loader!nls/measurement_t'
], function (moment,
             t) {

    var ModuleDate = {

        moment: moment,

        TIMEZONE: undefined,

        USE_24_HOURS_CLOCK: true,
        SHORT_FORMAT: '%m/%d',
        LONG_FORMAT: '%m/%d/%Y',

        create: function () {
            var args = Array.prototype.slice.call(arguments);
            args.push(ModuleDate.TIMEZONE);
            return moment.tz.apply(moment, args);
        },

        now: function () {
            return ModuleDate.create();
        },

        duration: function () {
            return moment.duration.apply(moment, arguments);
        },

        setLocale: function (locale) {
            moment.locale(locale);
        },

        /**
         * @param dayNumber (1 - Monday, 0 - Sunday)
         */
        setFirstDayOfWeek: function (dayNumber) {
            moment.setFirstDayOfWeek(dayNumber);
        },

        _getTimeFormat: function (options) {
            var is24Hour = ModuleDate.USE_24_HOURS_CLOCK;
            var hours = is24Hour ? "%H" : '%I',
                minutes = ':%M',
                seconds = (options && (options.seconds === true)) ? ':%S' : '',
                ampm = is24Hour ? '' : ' %p';

            return hours + minutes + seconds + ampm;
        },

        _getDateFormat: function (options) {
            return (options && (options.year === false)) ? ModuleDate.SHORT_FORMAT : ModuleDate.LONG_FORMAT;
        },

        _getDateTimeFormat: function (options) {
            return ModuleDate._getDateFormat(options) + ' ' + ModuleDate._getTimeFormat(options)
        },

        getTimeFormat: function (options) {
            return moment.strftimeToMoment(ModuleDate._getTimeFormat(options));
        },

        getDateFormat: function (options) {
            return moment.strftimeToMoment(ModuleDate._getDateFormat(options));
        },

        getDateTimeFormat: function (options) {
            return moment.strftimeToMoment(ModuleDate._getDateTimeFormat(options));
        },

        getHighchartsDateTimeLabelFormats() {
            return {
                millisecond: ModuleDate.USE_24_HOURS_CLOCK ? '%H:%M:%S.%L' : '%I:%M:%S.%L %p',
                second: ModuleDate.USE_24_HOURS_CLOCK ? '%H:%M:%S' : '%I:%M:%S %p',
                minute: ModuleDate.USE_24_HOURS_CLOCK ? '%H:%M' : '%I:%M %p',
                hour: ModuleDate.USE_24_HOURS_CLOCK ? '%H:%M' : '%I:%M %p',
                day: '%e. %b',
                week: '%e. %b',
                month: '%b \'%y',
                year: '%Y'
            }
        },

        Format: {
            /*
             options:
             seconds: boolean (default: false)
             */
            time: function (datetime, options) {
                var momentObj = moment.isMoment(datetime) ? datetime : ModuleDate.create(datetime);
                return momentObj.strftime(ModuleDate.getTimeFormat(options));
            },
            /*
             options:
             year: boolean (default: true)
             */
            date: function (datetime, options) {
                var momentObj = moment.isMoment(datetime) ? datetime : ModuleDate.create(datetime);
                return momentObj.strftime(ModuleDate.getDateFormat(options));
            },
            /*
             options:
             year: boolean (default: true)
             seconds: boolean (default: false)
             */
            dateTime: function (datetime, options) {
                var momentObj = moment.isMoment(datetime) ? datetime : ModuleDate.create(datetime);
                return momentObj.strftime(ModuleDate.getDateTimeFormat(options));
            },

            SQLTime: function (datetime) {
                var momentObj = moment.isMoment(datetime) ? datetime : moment(datetime);
                return momentObj.format('HH:mm:ss');
            },
            SQLDate: function (datetime) {
                var momentObj = moment.isMoment(datetime) ? datetime : moment(datetime);
                return momentObj.format('YYYY-MM-DD');
            },
            SQLDateTime: function (datetime) {
                var momentObj = moment.isMoment(datetime) ? datetime : moment(datetime);
                return momentObj.format('YYYY-MM-DD HH:mm:ss');
            },

            duration: function (duration, options) {
                options = options || {};
                var showSeconds = _.isUndefined(options.seconds) ? true : options.seconds;
                var measurement = options.measurement || "seconds";
                var momentDuration = moment.duration(Math.abs(duration), measurement),
                    h = Math.floor(momentDuration.asHours()),
                    m = momentDuration.minutes(),
                    s = momentDuration.seconds();

                if (!showSeconds) {
                    m += Math.round(s / 60);
                    if (m >= 60) {
                        h += 1;
                        m -= 60;
                    }
                }

                let prefix = duration < 0 ? "-" : "";

                return prefix + (h < 10 ? ("0" + h) : h) +
                    ":" + (m < 10 ? ("0" + m) : m) +
                    (showSeconds ? (":" + (s < 10 ? ("0" + s) : s)) : "");
            },

            durationWithUnits: function (duration, options) {
                var d, h, m, s;
                options = options || {};
                var showSeconds = _.isUndefined(options.seconds) ? true : options.seconds;
                var showDays = _.isUndefined(options.days) ? true : options.days;
                var measurement = options.measurement || "seconds";
                var momentDuration = moment.duration(duration, measurement);

                if (showDays) {
                    d = Math.floor(momentDuration.asDays());
                    h = momentDuration.hours();
                } else {
                    h = Math.floor(momentDuration.asHours());
                }

                m = momentDuration.minutes();
                s = momentDuration.seconds();

                if (!showSeconds) {
                    m += Math.round(s / 60);
                    if (m >= 60) {
                        h += 1;
                        m -= 60;
                    }
                }

                return _([
                    (showDays && d > 0 ? (d + t.duration.units.short.day) : ""),
                    (h > 0 ? (h + t.duration.units.short.hour) : ""),
                    (m > 0 ? (m + t.duration.units.short.minute) : ""),
                    (showSeconds && s > 0 ? (s + t.duration.units.short.second) : "")
                ]).compact().join(" ");

            },

            timeBasedOn: function (datetime, baseDate, options) {
                if (_.isNull(datetime)) {
                    return ''
                }

                options = options || {};

                var dateZoned = ModuleDate.create(datetime),
                    dateOrig = _.isUndefined(baseDate) ? ModuleDate.now() : ModuleDate.create(baseDate),
                    format = ModuleDate.getTimeFormat(options);

                if (!dateZoned.isSame(dateOrig, 'year')) {
                    format += ' (' + ModuleDate.getDateFormat({year: true}) + ')';
                } else if (!dateZoned.isSame(dateOrig, 'day')) {
                    format += ' (' + ModuleDate.getDateFormat({year: false}) + ')';
                }

                return dateZoned.strftime(format);
            }
        }

    };

    return ModuleDate;
});
