const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let landmarkIconsCache = {};

export default {
    getMapLandmarkIcon(color, opacity) {
        return {
            iconUrl: this.getLandmarkURL(color, opacity),
            iconSize: [25, 25],
            iconAnchor: [20, 25],
        }
    },

    getLandmarkURL(color, opacity) {
        let cacheKey = `${color}:${opacity}`;
        let url = landmarkIconsCache[cacheKey];
        if (!url) {
            url = landmarkIconsCache[cacheKey] = URL_PREFIX + btoa(this.getLandmarkSVG(color, opacity));
        }
        return url;
    },

    getLandmarkSVG(color, opacity) {
        const _color = color || '#F44336';
        const _opacity = opacity || 0.96;

        return `
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 38 38">
                <style type="text/css">
                    .shadow{
                        fill:url(#SVGID_1_);
                    }
                    .staff{
                        fill:#3A3A3A;
                    }
                    .flag{
                        opacity:${_opacity};
                        fill:${_color};
                    }
                </style>
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="7" y1="37" x2="29.4188" y2="37">
                    <stop offset="0" style="stop-color:#FFFFFF;stop-opacity:0"/>
                    <stop offset="0.1842" style="stop-color:#C1C1C1;stop-opacity:0.0553"/>
                    <stop offset="0.4055" style="stop-color:#7D7D7D;stop-opacity:0.1217"/>
                    <stop offset="0.6059" style="stop-color:#474747;stop-opacity:0.1818"/>
                    <stop offset="0.778" style="stop-color:#202020;stop-opacity:0.2334"/>
                    <stop offset="0.9151" style="stop-color:#090909;stop-opacity:0.2745"/>
                    <stop offset="1" style="stop-color:#000000;stop-opacity:0.3"/>
                </linearGradient>
                <rect x="7" y="36" class="shadow" width="22.4188" height="2"/>
                <path class="staff" d="M30.6,38h-1.2c-0.2209,0-0.4-0.1791-0.4-0.4V1c0-0.5523,0.4477-1,1-1h0c0.5523,0,1,0.4477,1,1v36.6
                    C31,37.8209,30.8209,38,30.6,38z"/>
                <path class="flag" d="M7.1552,20.5003c-0.1154,0.0853-0.1274,0.2523-0.0147,0.3411c0.5779,0.4555,2.2951,1.6354,4.5757,1.6372
                    c4.3758,0.0013,6.397-3.6681,11.3071-3.6666c2.5441,0.0008,4.4382,0.9625,6.2221,2.0302c0.1502,0.0899,0.3403-0.0171,0.3405-0.1922
                    l0.0205-16.3482c0.0001-0.0759-0.0359-0.1464-0.0989-0.1889c-1.5974-1.0761-3.7-2.1122-6.462-2.113
                    c-5.198-0.0016-6.6316,3.8559-11.11,3.8545C9.859,5.8539,8.1469,4.8361,7.3752,4.2879C7.207,4.1684,6.9903,4.3235,7.0329,4.5254
                    c0.5982,2.8337,3.0137,7.1181,4.8269,8.7844c0.0657,0.0604,0.0853,0.1513,0.0542,0.2349
                    C10.9909,16.0251,9.3251,18.8976,7.1552,20.5003z"/>
            </svg>
        `;
    },
}
