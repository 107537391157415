define([
    'positrace',
    'base'
], function (Positrace,
             Base) {

    var Locate = Positrace.locate;

    /**
     * This service is initialize an app session
     *
     *
     * @module application
     * @class SessionService
     * @main service
     */
    var SessionService = Positrace.Object.extend({

        DEVICE_TOKEN_VERSION: 1,

        userEvents: {
            'change:token': 'onChangeUserToken'
        },

        initialize: function () {
            this.app = this.getOption('app');
            this.rememberService = this.app.request('remember');
            // If the user is on the auth page, there was not impersonation. So we should reset impersonation status.
            this.resetImpersonationStatus();
            return Positrace.Object.prototype.initialize.apply(this, arguments);
        },

        getSession: function () {
            this.sessionToken = null;
            this.userToken = null;
            this.deviceToken = null;
            this.user = new Locate.model.UserModel({
                token: this.userToken
            });
            this.session = new Locate.auth.Session({
                user: this.user,
                token: this.sessionToken
            });
            this.bindEntityEvents(this.user, this.userEvents);
            this.app.reqres.setHandler('session', function () {
                return this.session;
            }, this);
            return this.session;
        },

        onChangeUserToken: function (user, value) {
            this.userToken = this.remember('user-token', value);
        },

        /**
         @method setDeviceToken
         @param {String} value
         */

        setDeviceToken: function (value) {
            this.session.deviceToken = value;
            this.remember('device-token-ver', this.DEVICE_TOKEN_VERSION);
            return this.deviceToken = this.remember('device-token', value);
        },


        /**
         Remember value in app store

         @method remember
         @param {String} key
         @param {Mixed} value
         @return {Mixed}
         */

        remember: function (key, value) {
            return this.app.remember(key, value);
        },

        forgotDeviceToken: function () {
            this.remember('device-token', null);
            this.remember('device-token-ver', null);
        },

        /**
         Get device token

         if a device token was generated it will return it,
         otherwise function will make an ajax request to generate new token

         @method getDeviceToken
         @return {Promise}
         */

        getDeviceToken: function (uid, apiKey) {
            return this.rememberService.storage
                .get('device-token', 'device-token-ver')
                .then(_.bind(function (resp) {
                    var cachedToken = resp[0];
                    var cachedTokenVersion = resp[1];
                    if (cachedToken && cachedTokenVersion == this.DEVICE_TOKEN_VERSION) {
                        return $.Deferred().resolve(cachedToken).promise();
                    }
                    var deviceTokenModel = new Locate.model.DeviceTokenModel;

                    return deviceTokenModel
                        .create(uid, apiKey)
                        .then(_.bind(function () {
                            return this.setDeviceToken(deviceTokenModel.get('token'));
                        }, this))
                        .then(_.bind(function () {
                            return $.Deferred().resolve(this.deviceToken).promise();
                        }, this));
                }, this));
        },


        /**
         Try restore session

         @method restoreSession
         @param {String} deviceToken
         @return {Promise}
         */

        restoreSession: function (deviceToken) {
            if (deviceToken) {
                this.session.deviceToken = deviceToken;
            }
        },

        resetImpersonationStatus() {
            this.rememberService.set('is-impersonated', null);
        },

        onDestroy: function () {
            this.unbindEntityEvents(this.user, this.userEvents);
        }

    });

    return SessionService;

});
