define(['moment-timezone'], function (moment) {

    // USE default AM/PM logic instead of localized
    moment.updateLocale('ru', {meridiemParse: null, isPM: null, meridiem: null});
    moment.updateLocale('uk', {meridiemParse: null, isPM: null, meridiem: null});

    // Functions of converting to SQL format
    moment.fn.formatSQLDate = function () {
        return this.format('YYYY-MM-DD');
    };
    moment.fn.formatSQLTime = function () {
        return this.format('HH:mm:ss');
    };
    moment.fn.formatSQLDateTime = function () {
        return this.format('YYYY-MM-DD HH:mm:ss');
    };

    /**
     * Apply year, month and date from 'date'
     * @param {Moment} date
     */
    moment.fn.setDateFrom = function (date) {
        if (moment.isMoment(date)) {
            return this.set({
                year: date.get('year'),
                month: date.get('month'),
                date: date.get('date')
            });
        }
    };

    /**
     * Redefine first day of week value in locale params
     * @param day
     */
    moment.setFirstDayOfWeek = function (day) {
        //TODO: be careful on updating moment.js
        moment.updateLocale(moment.locale(), {
            week: {
                dow: day, // Use Monday or Saturday as first day of week
                doy: moment.localeData()._week.doy  // Use first day of year defined in locale
            }
        });
    };

    // Modified moment-strftime plugin
    var strftimeToMomentReplacements = {
        a: "ddd",
        A: "dddd",
        b: "MMM",
        B: "MMMM",
        d: "DD",
        e: "D",
        F: "YYYY-MM-DD",
        H: "HH",
        I: "hh",
        j: "DDDD",
        m: "MM",
        M: "mm",
        p: "A",
        S: "ss",
        Z: "z",
        w: "d",
        y: "YY",
        Y: "YYYY",
        "%": "%"
    };
    var _strftimeToMoment = function (format) {
        var key, momentFormat, value;
        momentFormat = format;
        for (key in strftimeToMomentReplacements) {
            value = strftimeToMomentReplacements[key];
            momentFormat = momentFormat.replace("%" + key, value)
        }
        return momentFormat;
    };
    moment.fn.strftime = function (format) {
        return this.format(_strftimeToMoment(format))
    };
    moment.strftimeToMoment = _strftimeToMoment;

    var strftimeToDateReplacements = {
        a: "D",     // Abbreviated weekday names. Eg, Mon
        A: "DD",    // Full weekday names. Eg, Monday
        b: "M",     // Abbreviated month names. Eg, Jan
        B: "MM",    // Full month names. Eg, January
        d: "dd",    // Numeric date, leading zero. Eg, 05
        e: "d",     // Numeric date, no leading zero. Eg, 5
        m: "mm",    // Numeric month, leading zero. Eg, 07
        y: "yy",    // 2-digit years. Eg, 12
        Y: "yyyy",  // 4-digit years. Eg, 2012
        "%": "%"
    };
    var _strftimeToDate = function (format) {
        var key, momentFormat, value;
        momentFormat = format;
        for (key in strftimeToDateReplacements) {
            value = strftimeToDateReplacements[key];
            momentFormat = momentFormat.replace("%" + key, value)
        }
        return momentFormat;
    };
    moment.strftimeToDate = _strftimeToDate;

    return moment;

});