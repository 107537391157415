const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let assetIconsCache = {};
let assetTypeIconsCache = {};

const AssetTypeIcons = {
    cargo: require('!svg-inline-loader!./assets/cargo.svg'),
    not_assigned: require('!svg-inline-loader!./assets/not_assigned.svg'),
    tools: require('!svg-inline-loader!./assets/tools.svg'),
    trailer: require('!svg-inline-loader!./assets/trailer.svg'),
};

export default {
    getAssetTypeURL(type, color) {
        if (!type) return '';
        let cacheKey = `${type}`;
        let url = assetTypeIconsCache[cacheKey];
        if (!url) {
            const icon = type && AssetTypeIcons[type] || AssetTypeIcons['not_assigned'];
            const iconFill = color || '#333333';
            const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style = "fill: ${iconFill};">${icon}</svg>`;
            url = assetTypeIconsCache[cacheKey] = `${URL_PREFIX}${btoa(svg)}`;
        }
        return url;
    },

    getAssetURL(color, type) {
        let cacheKey = `${color}:${type}`;
        let url = assetIconsCache[cacheKey];
        if (!url) {
            url = assetIconsCache[cacheKey] = URL_PREFIX + btoa(this.getAssetSVG(color, type));
        }
        return url;
    },

    getAssetSVG(color = '#777', type) {
        let viewBox = "0 0 22 32";
        let iconFill = Base.colors.getContrastColor(color);
        let typeIcon = type && AssetTypeIcons[type] || AssetTypeIcons['not_assigned'];
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}">
              <defs>
                <style>
                  #asset_pin {fill: ${color};}
                </style>
              </defs>
              <path id="asset_pin" d="M17.6,0H4.4C2,0,0,2,0,4.4v13.2C0,20,2,22,4.4,22h1.1l5,9.7c0.2,0.4,0.8,0.4,1,0l5-9.7h1.1c2.4,0,4.4-2,4.4-4.5V4.4C22,2,20,0,17.6,0z"/>
              <svg height="14" width="14" x="4" y="4" style="fill: ${iconFill};">
                ${typeIcon}
              </svg>
            </svg>
        `;
    },

    getMapAssetIcon(color, type) {
        return {
            iconUrl: this.getAssetURL(color, type),
            iconSize: [22, 32],
            iconAnchor: [11, 32],
        }
    },

    getAssetGroupSVG(numberOfItems) {
        return `
            <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <style>
                    .assets-count-text {
                        font: 8px sans-serif;
                        fill: #4E4E4E;
                    }
                </style>
                <g filter="url(#filter0_d_406_1511)">
                    <g opacity="0.9">
                        <rect x="8.5" y="10.5" width="23" height="23" rx="3.5" fill="#4E4E4E"/>
                        <path d="M14.1133 18.96L20 22.3667L25.8467 18.98" stroke="white" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M20 28.4067V22.36" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.6204 15.6535L15.0604 17.6269C14.2538 18.0735 13.5938 19.1935 13.5938 20.1135V23.8802C13.5938 24.8002 14.2538 25.9202 15.0604 26.3669L18.6204 28.3469C19.3804 28.7669 20.6271 28.7669 21.3871 28.3469L24.9471 26.3669C25.7538 25.9202 26.4138 24.8002 26.4138 23.8802V20.1135C26.4138 19.1935 25.7538 18.0735 24.9471 17.6269L21.3871 15.6469C20.6204 15.2269 19.3804 15.2269 18.6204 15.6535Z"
                              stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <rect x="8.5" y="10.5" width="23" height="23" rx="3.5" stroke="white"/>
                    </g>
                    <g opacity="0.9">
                        <rect x="6.5" y="8.5" width="23" height="23" rx="3.5" fill="#4E4E4E"/>
                        <path d="M12.1133 16.96L18 20.3667L23.8467 16.98" stroke="white" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M18 26.4067V20.36" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.6204 13.6535L13.0604 15.6269C12.2538 16.0735 11.5938 17.1935 11.5938 18.1135V21.8802C11.5938 22.8002 12.2538 23.9202 13.0604 24.3669L16.6204 26.3469C17.3804 26.7669 18.6271 26.7669 19.3871 26.3469L22.9471 24.3669C23.7538 23.9202 24.4138 22.8002 24.4138 21.8802V18.1135C24.4138 17.1935 23.7538 16.0735 22.9471 15.6269L19.3871 13.6469C18.6204 13.2269 17.3804 13.2269 16.6204 13.6535Z"
                              stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <rect x="6.5" y="8.5" width="23" height="23" rx="3.5" stroke="white"/>
                    </g>
                    <g filter="url(#filter1_d_406_1511)">
                        <rect x="4" y="6" width="24" height="24" rx="4" fill="#4E4E4E" shape-rendering="crispEdges"/>
                        <g clip-path="url(#clip0_406_1511)">
                            <path d="M10.1133 14.96L16 18.3667L21.8467 14.98" stroke="white" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M16 24.4067V18.36" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.6204 11.6535L11.0604 13.6269C10.2537 14.0735 9.59375 15.1935 9.59375 16.1135V19.8802C9.59375 20.8002 10.2537 21.9202 11.0604 22.3669L14.6204 24.3469C15.3804 24.7669 16.6271 24.7669 17.3871 24.3469L20.9471 22.3669C21.7537 21.9202 22.4138 20.8002 22.4138 19.8802V16.1135C22.4138 15.1935 21.7537 14.0735 20.9471 13.6269L17.3871 11.6469C16.6204 11.2269 15.3804 11.2269 14.6204 11.6535Z"
                                  stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <rect x="4.5" y="6.5" width="23" height="23" rx="3.5" stroke="white" shape-rendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter2_d_406_1511)">
                        <rect x="20" width="17" height="12" rx="6" fill="white"/>
                        <text x="28.5" y="7" text-anchor="middle" alignment-baseline="middle" class="assets-count-text">
                            ${numberOfItems}
                        </text>
                        <rect x="20.5" y="0.5" width="16" height="11" rx="5.5" stroke="#4E4E4E"/>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_d_406_1511" x="0" y="0" width="41" height="42" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_406_1511"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_406_1511" result="shape"/>
                    </filter>
                    <filter id="filter1_d_406_1511" x="2" y="6" width="28" height="28" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="2"/>
                        <feGaussianBlur stdDeviation="1"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_406_1511"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_406_1511" result="shape"/>
                    </filter>
                    <filter id="filter2_d_406_1511" x="16" y="0" width="25" height="20" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_406_1511"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_406_1511" result="shape"/>
                    </filter>
                    <clipPath id="clip0_406_1511">
                        <rect width="16" height="16" fill="white" transform="translate(8 10)"/>
                    </clipPath>
                </defs>
            </svg>
        `;
    },

    getAssetGroupURL(numberOfItems) {
        let cacheKey = `${numberOfItems}`;
        let url = assetIconsCache[cacheKey];
        if (!url) {
            url = assetIconsCache[cacheKey] = URL_PREFIX + btoa(this.getAssetGroupSVG(numberOfItems));
        }
        return url;
    },

    getMapAssetGroupIcon(numberOfItems) {
        return {
            iconUrl: this.getAssetGroupURL(numberOfItems),
            iconSize: [41, 42],
            iconAnchor: [20.5, 21],
        }
    },

}
