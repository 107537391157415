define([], function () {
    return {
        AccountDecorator: require('./AccountDecorator'),
        AssetDecorator: require('./AssetDecorator').default,
        DriverDecorator: require('./DriverDecorator'),
        EquipmentDecorator: require('./EquipmentDecorator'),
        GeofenceDecorator: require('./GeofenceDecorator'),
        GroupDecorator: require('./GroupDecorator'),
        LandmarkDecorator: require('./LandmarkDecorator')
    };
});
