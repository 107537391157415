import Positrace from 'positrace';

const EquipmentModel = Positrace.locate.model.EquipmentModel;
const FULL_EQUIPMENT_LIST_LIMIT = 600;

const DATA_REQUEST_OPTIONS = {
    data: {
        filters: {
            device_exists: true
        }
    }
};

const PINNED_DATA_REQUEST_OPTIONS = {
    data: {
        filters: {
            device_exists: true,
            pinned: true
        }
    }
};

export class EquipmentsStorage {

    constructor() {
        this.isFetchedSimplified = false;
        this.isFetchingSimplified = false;
        this.isFetchedPinned = false;
        this.isFetchingPinned = false;
        this.isFetchedPinnedSimplified = false;
        this.isFetchingPinnedSimplified = false;
        this.simplifiedListRequest = undefined;
        this.simplifiedPinnedListRequest = undefined;
    }

    getPaginator() {
        return LocateApp.request('paginator:equipment');
    }

    getCollection() {
        return this.getPaginator().collection;
    }

    isFetchingCollection() {
        return _.some([
            this.isFetchingSimplified,
            this.isFetchingPinned,
            this.isFetchingPinnedSimplified,
        ]);
    }

    async requestFullList() {
        const paginator = this.getPaginator();

        if (!paginator.isFetched && !paginator.isFetching) {
            paginator.fetchAll(DATA_REQUEST_OPTIONS);
        }
        if (paginator.isFetching) {
            return new Promise((resolve, reject) => {
                paginator.once('response:complete', () => {
                    resolve(paginator.collection);
                });
            });
        } else {
            return paginator.collection;
        }
    }

    async requestPinnedFullList() {
        const paginator = this.getPaginator();

        if (paginator.isFetched || this.isFetchedPinned) {
            return paginator.collection;
        }

        if (paginator.isFetching) {
            return new Promise((resolve, reject) => {
                paginator.once('response:complete', () => {
                    resolve(paginator.collection);
                });
            });
        }

        if (!this.isFetchingPinned) {
            this.simplifiedListRequest =
                paginator
                    .fetchAll(PINNED_DATA_REQUEST_OPTIONS)
                    .then(() => {
                        this.isFetchingPinned = false;
                        this.isFetchedPinned = true;
                        paginator.isFetched = false;
                        paginator.page = 0;
                    })
            this.isFetchingPinned = true;
        }

        return this.simplifiedListRequest.then(() => {
            return paginator.collection;
        });
    }

    requestPinnedOrAllFullList() {
        if (LocateApp.session.user.mapSettings.get('show_only_pinned_equipment')) {
            return equipmentsStorage.requestPinnedFullList();
        } else {
            return equipmentsStorage.requestFullList();
        }
    }

    requestFullListOfPreloaded() {
        if (this.isFetchedSimplified || this.isFetchingSimplified) {
            return equipmentsStorage.requestFullList();
        } else if (this.isFetchedPinnedSimplified || this.isFetchingPinnedSimplified) {
            return equipmentsStorage.requestPinnedFullList();
        }
    }

    _requestSimplifiedList() {
        return new Promise((resolve, reject) => {
            const paginator = this.getPaginator();
            const equipmentCollection = this.getCollection();
            if (this.isFetchedSimplified) {
                return resolve(equipmentCollection);
            }
            if (!this.isFetchingSimplified) {
                const collectionLengthBeforeRequest = equipmentCollection.length;
                this.simplifiedListRequest =
                    equipmentCollection
                        .fetchSimplified(DATA_REQUEST_OPTIONS)
                        .then(() => {
                            this.isFetchingSimplified = false;
                            this.isFetchedSimplified = true;

                            if (equipmentCollection.length !== collectionLengthBeforeRequest) {
                                paginator.trigger('length:changed');
                            }
                        })
                this.isFetchingSimplified = true;
            }
            this.simplifiedListRequest
                .then(() => {
                    resolve(equipmentCollection);
                })
        });
    }

    _requestPinnedSimplifiedList() {
        return new Promise((resolve, reject) => {
            const paginator = this.getPaginator();
            const equipmentCollection = this.getCollection();
            if (this.isFetchedPinnedSimplified || this.isFetchedSimplified) {
                return resolve(equipmentCollection);
            }
            if (this.isFetchingSimplified) {
                return this.simplifiedListRequest
                    .then(() => {
                        resolve(equipmentCollection);
                    })
            }
            if (!this.isFetchingPinnedSimplified) {
                const collectionLengthBeforeRequest = equipmentCollection.length;
                this.simplifiedPinnedListRequest =
                    equipmentCollection
                        .fetchSimplified(PINNED_DATA_REQUEST_OPTIONS)
                        .then(() => {
                            this.isFetchingPinnedSimplified = false;
                            this.isFetchedPinnedSimplified = true;

                            if (equipmentCollection.length !== collectionLengthBeforeRequest) {
                                paginator.trigger('length:changed');
                            }
                        })
                this.isFetchingPinnedSimplified = true;
            }
            this.simplifiedPinnedListRequest
                .then(() => {
                    resolve(equipmentCollection);
                })
        });
    }

    requestInitialForLiveMap() {
        if (LocateApp.session.user.mapSettings.get('show_only_pinned_equipment')) {
            return equipmentsStorage.requestPinnedList();
        } else {
            return equipmentsStorage.requestList();
        }
    }

    requestPinnedList() {
        const equipmentPaginator = this.getPaginator();
        const isReadyFullList = _.some([
            equipmentPaginator.isFetched,
            equipmentPaginator.isFetching,
            this.isFetchedPinned,
            this.isFetchingPinned
        ]);
        if (isReadyFullList) {
            return this.requestPinnedFullList();
        } else {
            return this._requestPinnedSimplifiedList();
        }
    }

    requestList() {
        const equipmentPaginator = this.getPaginator();
        if (equipmentPaginator.isFetched || equipmentPaginator.isFetching) {
            return this.requestFullList();
        } else {
            return this._requestSimplifiedList();
        }
    }

    requestFullListIfSmallAccount() {
        if (this.getCollection().length <= FULL_EQUIPMENT_LIST_LIMIT) {
            return this.requestPinnedOrAllFullList();
        }
    }

    requestFullModels(equipmentIds) {
        const equipmentCollection = this.getCollection();
        const reducedIds = equipmentIds.filter(equipmentId => {
            const equipmentModel = equipmentCollection.get(equipmentId);
            return !equipmentModel || !equipmentModel.isFullyLoaded();
        });

        if (reducedIds.length) {
            return new Promise((resolve, reject) => {
                this.getCollection()
                    .fetch({data: {filters: {ids: reducedIds}}, remove: false})
                    .done(() => resolve())
                    .fail(() => reject());
            });
        } else {
            return Promise.resolve();
        }
    }

    requestFullModel(equipmentId) {
        const equipmentCollection = equipmentsStorage.getCollection();
        equipmentCollection.add(new EquipmentModel({id: equipmentId}));
        const equipmentModel = equipmentCollection.get(equipmentId);

        return new Promise((resolve, reject) => {
            if (equipmentModel.isFullyLoaded()) {
                return resolve(equipmentModel);
            } else {
                equipmentModel
                    .fetch()
                    .done(() => resolve(equipmentModel))
                    .fail(() => reject())
            }
        });
    }

}

export const equipmentsStorage = new EquipmentsStorage();
