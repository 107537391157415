define([
    'amdi18n-loader!nls/measurement_t'
], function (t) {

    var Units = {};

    Units.t = t;

    Units.round = function (number, precision) {
        precision = precision || 0;
        var factor = Math.pow(10, precision);
        var tempNumber = number * factor;
        var roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
    };

    Units.IS_IMPERIAL_MEASUREMENT = true;
    Units.IS_CELSIUS_TEMPERATURE = false;
    Units.TIRE_PRESSURE = "kPa";
    Units.FUEL_PRICE = 1.3;
    Units.CURRENCY = {
        "name": "Canadian Dollar",
        "code": "CAD",
        "exchange_rate": 1.0
    };
    Units.FUEL_CONSUMPTION_RATE_FORMAT = 'volume/100 distance';

    Units.Currency = {
        ABBR_TO_UNICODE_MAP: {
            "CAD": '\u0024', //$
            "USD": '\u0024', //$
            "GBP": '\u00A3', //£
            "AUD": '\u0024', //$
            "EUR": '\u20AC', //€
            "MXN": '\u0024', //$
            "UAH": '\u20B4', //₴
            "PHP": '\u20B1'  //₱
        },
        getName: function () {
            return Units.CURRENCY.name;
        },
        getUnicodeSymbol: function (code) {
            code = code || Units.CURRENCY.code;
            return Units.Currency.ABBR_TO_UNICODE_MAP[code];
        }
    };

    Units.Heading = {
        degrees: function (deg_n) {
            return deg_n.toFixed(0) % 360 + '\u00b0';
        },

        radians: function (deg_n) {
            return (deg_n * Math.PI / 180.0).toFixed(3);
        },

        _discrete: function (deg_n, names) {
            return names[Math.round(names.length * deg_n / 360.0) % names.length];
        },

        cardinal_4way: function (deg_n) {
            return Units.Heading._discrete(deg_n, [
                t.measurements.north_short, t.measurements.east_short, t.measurements.south_short, t.measurements.west_short
            ]);
        },

        cardinal_4way_long: function (deg_n) {
            return Units.Heading._discrete(deg_n, [
                t.measurements.north, t.measurements.east, t.measurements.south, t.measurements.west
            ]);
        },

        cardinal_8way: function (deg_n) {
            return Units.Heading._discrete(deg_n, [
                t.measurements.north_short, t.measurements.northeast_short, t.measurements.east_short, t.measurements.southeast_short,
                t.measurements.south_short, t.measurements.southwest_short, t.measurements.west_short, t.measurements.northwest_short
            ]);
        },

        cardinal_8way_long: function (deg_n) {
            return Units.Heading._discrete(deg_n, [
                t.measurements.north, t.measurements.northeast, t.measurements.east, t.measurements.southeast,
                t.measurements.south, t.measurements.southwest, t.measurements.west, t.measurements.northwest
            ]);
        },

        cardinal_16way: function (deg_n) {
            return Units.Heading._discrete(deg_n, [
                t.measurements.north_short, t.measurements.north_northeast_short, t.measurements.northeast_short, t.measurements.east_northeast_short,
                t.measurements.east_short, t.measurements.east_southeast_short, t.measurements.southeast_short, t.measurements.south_southeast_short,
                t.measurements.south_short, t.measurements.south_southwest_short, t.measurements.southwest_short, t.measurements.west_southwest_short,
                t.measurements.west_short, t.measurements.west_northwest_short, t.measurements.northwest_short, t.measurements.north_northwest_short
            ]);
        }
    };

    var createConverter = function (specification, imperial, metric) {
        return (function () {
            var defaultMeasurement;

            for (var s in specification) {
                if (specification[s].def === true) {
                    defaultMeasurement = s;
                    break;
                }
            }

            var getMeasurement = function () {
                return Units.IS_IMPERIAL_MEASUREMENT ? imperial : metric;
            };

            var value = function (val, options) {
                var output, input;
                if (options && options.reverse) {
                    options.input = getMeasurement();
                    options.output = defaultMeasurement;
                }
                output = (options && options.output) ? options.output : getMeasurement();
                input = (options && options.input) ? options.input : defaultMeasurement;

                if (input == output) {
                    return val;
                }

                let result = ((input == defaultMeasurement) ? val : val / specification[input].ratio) * specification[output].ratio;

                return (options && _.isNumber(options.precision)) ? Units.round(result, options.precision) : result;
            };

            var name = function (options) {
                var output = (options && options.output) ? options.output : getMeasurement();
                return specification[output].name;
            };

            var format = function (val, options) {
                var output = (options && options.output) ? options.output : getMeasurement();
                var precision = _.isNumber(specification[output].precision) ? specification[output].precision : 2;
                if (options && _.isNumber(options.precision)) {
                    precision = options.precision;
                }
                var v = +value(val, options);
                var n = name(options);
                return Units.round(v, precision) + ' ' + n;
            };

            return {
                value: value,
                name: name,
                format: format
            }

        })();
    };

    Units.Voltage = {
        /**
         * Convert voltage value in mV to string with voltage in V
         * @param value Voltage in mV
         * @returns {string}
         */
        value: function (value) {
            return Units.round((value / 1000), 2);
        },
        format: function (value) {
            return Units.Voltage.value(value) + ' V'
        }
    };

    Units.Temperature = {
        valueToCelsius: function (value, options) {
            options = options || {};
            const precision = (options && _.isNumber(options.precision)) ? options.precision : 2;
            value = parseFloat(value);
            if (Units.IS_CELSIUS_TEMPERATURE) {
                return Units.round(value, precision);
            } else {
                return Units.round(this.fahrenheitToCelsius(value), precision);
            }
        },
        value: function (value) {
            value = parseFloat(value);
            if (Units.IS_CELSIUS_TEMPERATURE) {
                return Units.round(value, 2);
            } else {
                return Units.round(this.celsiusToFahrenheit(value), 2);
            }
        },
        fahrenheitToCelsius: function (value) {
            return ((value - 32) * (5 / 9));
        },
        celsiusToFahrenheit: function (value) {
            return ((value * (9 / 5)) + 32);
        },
        name: function () {
            return Units.IS_CELSIUS_TEMPERATURE ? "\u00b0C" : "\u00b0F"
        },
        format: function (value) {
            return Units.Temperature.value(value) + Units.Temperature.name();
        }
    };

    Units.Pressure = {
        value(value, options) {
            const specification = {
                'Pa': {ratio: 1, def: true},
                'kPa': {ratio: 1 / 1000},
                'bar': {ratio: 1 / 100000},
                'psi': {ratio: 1 / 6895},
            };
            const defaultMeasurement = 'Pa';
            if (options && options.reverse) {
                options.input = Units.TIRE_PRESSURE;
                options.output = defaultMeasurement;
            }
            let output = (options && options.output) ? options.output : Units.TIRE_PRESSURE;
            let input = (options && options.input) ? options.input : defaultMeasurement;

            if (input === output) {
                return value;
            }

            let result = ((input === defaultMeasurement) ? value : value / specification[input].ratio) * specification[output].ratio;

            return (options && _.isNumber(options.precision)) ? Units.round(result, options.precision) : result;
        },
        name(options) {
            const output = (options && options.output) ? options.output : Units.TIRE_PRESSURE;
            return t.measurements.pressure[output];
        },
        format(value, options) {
            const precision = (options && _.isNumber(options.precision)) ? options.precision : 2;

            return `${Units.Pressure.value(value, {...options, precision})} ${Units.Pressure.name(options)}`;
        },
    };

    Units.FuelEfficiency = {
        _getMeasurement: function () {
            return Units.IS_IMPERIAL_MEASUREMENT ? 'mpg' : 'litres_per_100_km';
        },
        value: function (value, options) {
            const measurement = Units.FuelEfficiency._getMeasurement();
            const defaultMeasurement = 'litres_per_100_km';
            if (options && options.reverse) {
                options.input = measurement;
                options.output = defaultMeasurement;
            }
            const output = (options && options.output) ? options.output : measurement;
            const input = (options && options.input) ? options.input : defaultMeasurement;

            let result;

            if (input === output) {
                result = value;
            } else {
                result = 235.2145832948 / value;
            }

            return (options && _.isNumber(options.precision)) ? Units.round(result, options.precision) : result;
        },
        name: function (options) {
            const output = (options && options.output) ? options.output : Units.FuelEfficiency._getMeasurement();
            return output === 'mpg' ? 'mpg' : 'l/100km';
        },
        format: function (value, options) {
            const precision = (options && _.isNumber(options.precision)) ? options.precision : 2;

            return Units.round(Units.FuelEfficiency.value(value, options), precision) + Units.FuelEfficiency.name(options);
        },
    };

    Units.FuelConsumptionRate = {
        formatName(isImperial, formula) {
            if (isImperial) {
                // IMPERIAL
                return formula === 'distance/volume'
                    ? Units.t.fuel_consumption_rate_format.imperial.distance_per_volume
                    : Units.t.fuel_consumption_rate_format.imperial.volume_per_100distance;
            } else {
                // METRIC
                return formula === 'distance/volume'
                    ? Units.t.fuel_consumption_rate_format.metric.distance_per_volume
                    : Units.t.fuel_consumption_rate_format.metric.volume_per_100distance;
            }
        },
        _specification: {
            'liters_per_meter': {
                def: true,
                ratio: 1,
                inverted: false,
                name: 'L/m'
            },
            'kilometers_per_liter': {
                ratio: 1000,
                inverted: true,
                name: Units.t.fuel_consumption_rate_format.metric.distance_per_volume
            },
            'liters_per_100_kilometers': {
                ratio: 100000,
                inverted: false,
                name: Units.t.fuel_consumption_rate_format.metric.volume_per_100distance
            },
            'miles_per_gallon': {
                ratio: 425.1437075,
                inverted: true,
                name: Units.t.fuel_consumption_rate_format.imperial.distance_per_volume
            },
            'gallons_per_100_miles': {
                ratio: 42514.37075,
                inverted: false,
                name: Units.t.fuel_consumption_rate_format.imperial.volume_per_100distance
            },
        },
        /**
         * @typedef {'liters_per_meter' | 'miles_per_gallon' | 'gallons_per_100_miles' | 'kilometers_per_liter' | 'liters_per_100_kilometers'} FuelConsumptionRateMeasurement
         */
        /**
         * @returns {FuelConsumptionRateMeasurement}
         */
        _getAPIMeasurement() {
            return 'liters_per_meter';
        },
        /**
         * @returns {FuelConsumptionRateMeasurement}
         */
        _getOutputMeasurement() {
            if (Units.IS_IMPERIAL_MEASUREMENT) {
                // IMPERIAL
                return Units.FUEL_CONSUMPTION_RATE_FORMAT === 'distance/volume'
                    ? 'miles_per_gallon'
                    : 'gallons_per_100_miles';
            } else {
                // METRIC
                return Units.FUEL_CONSUMPTION_RATE_FORMAT === 'distance/volume'
                    ? 'kilometers_per_liter'
                    : 'liters_per_100_kilometers';
            }
        },
        /**
         * @typedef {Object} FuelConsumptionRateFormatterOptions
         * @property {FuelConsumptionRateMeasurement} [input]
         * @property {FuelConsumptionRateMeasurement} [output]
         * @property {boolean} [reverse]
         * @property {number} [precision]
         */
        /**
         * @param {number} value
         * @param {FuelConsumptionRateFormatterOptions} [options]
         */
        value: function (value, options) {
            const measurement = this._getOutputMeasurement();
            const defaultAPIMeasurement = this._getAPIMeasurement();
            if (options && options.reverse) {
                options.input = measurement;
                options.output = defaultAPIMeasurement;
            }
            const outputMeasurement = (options && options.output) ? options.output : measurement;
            const inputMeasurement = (options && options.input) ? options.input : defaultAPIMeasurement;

            let result;

            if (inputMeasurement === outputMeasurement) {
                result = value;
            } else if (value === 0) {
                result = value;
            } else {
                const inputRatio = this._specification[inputMeasurement].ratio;
                const inputIsInverted = this._specification[inputMeasurement].inverted;

                const outputRatio = this._specification[outputMeasurement].ratio;
                const outputIsInverted = this._specification[outputMeasurement].inverted;

                let valueInDefMeasurement;
                if (inputMeasurement === defaultAPIMeasurement) {
                    valueInDefMeasurement = value;
                } else if (inputIsInverted) {
                    valueInDefMeasurement = 1 / value / inputRatio;
                } else {
                    valueInDefMeasurement = value / inputRatio
                }

                if (outputIsInverted) {
                    result = 1 / (valueInDefMeasurement * outputRatio);
                } else {
                    result = valueInDefMeasurement * outputRatio;
                }
            }

            return (options && _.isNumber(options.precision)) ? Units.round(result, options.precision) : result;
        },
        /**
         * @param {FuelConsumptionRateFormatterOptions} [options]
         * @returns {string}
         */
        name: function (options) {
            const output = (options && options.output) ? options.output : this._getOutputMeasurement();

            return this._specification[output].name;
        },
        /**
         * @param {number} value
         * @param {FuelConsumptionRateFormatterOptions} [options]
         * @returns {string}
         */
        format: function (value, options) {
            const precision = (options && _.isNumber(options.precision)) ? options.precision : 2;

            return Units.round(this.value(value, options), precision) + ' ' + this.name(options);
        },
    };

    Units.RSSI = {
        toApproxDistance(value) {
            const _td = (value) => {
                const output = Units.IS_IMPERIAL_MEASUREMENT ? 'feet' : 'meters';

                return Units.Distance.value(Number(value), {precision: 0, input: 'meters', output})
            };

            const units = Units.IS_IMPERIAL_MEASUREMENT ? t.measurements.feet : t.measurements.meters;

            const map = [
                [-30, () => `${t.up_to} ${_td(2)} ${units}`],
                [-40, () => `${_td(2)}-${_td(4)} ${units}`],
                [-45, () => `${_td(4)}-${_td(6)} ${units}`],
                [-50, () => `${_td(6)}-${_td(8)} ${units}`],
                [-55, () => `${_td(8)}-${_td(10)} ${units}`],
                [-60, () => `${_td(10)}-${_td(15)} ${units}`],
                [-65, () => `${_td(15)}-${_td(20)} ${units}`],
                [-Infinity, () => `${t.over} ${_td(20)} ${units}`]
            ];

            const result = map.find((mapValue) => value >= mapValue[0]);

            return result[1]();
        }
    };

    Units.Area = createConverter({
        'square_meters': {ratio: 1, name: t.measurements.square_meters, def: true},
        'hectares': {ratio: 1 / 10000, name: t.measurements.hectares},
        'acres': {ratio: 1 / 4046.8564224, name: t.measurements.acres}
    }, 'acres', 'hectares');

    Units.Distance = createConverter({
        'meters': {ratio: 1, name: t.measurements.meters, def: true},
        'feet': {ratio: 3.28084, name: t.measurements.feet},
        'kilometers': {ratio: 0.001, name: t.measurements.km},
        'miles': {ratio: 0.0006213711922373339, name: t.measurements.mi}
    }, 'miles', 'kilometers');

    Units.Speed = createConverter({
        'meters_per_second': {ratio: 1, name: t.measurements.meters_per_second, precision: 1, def: true},
        'kilometers_per_hour': {ratio: 3.6, name: t.measurements.kilometers_per_hour, precision: 1},
        'miles_per_hour': {ratio: 2.2369362920544025, name: t.measurements.miles_per_hour, precision: 1}
    }, 'miles_per_hour', 'kilometers_per_hour');

    Units.FuelVolume = createConverter({
        'litres': {ratio: 1, name: t.measurements.litres, def: true},
        'gallons': {ratio: 0.2641720523581484, name: t.measurements.gallons}
    }, 'gallons', 'litres');

    Units.PerFuelVolume = createConverter({
        'litres': {ratio: 1, name: t.measurements.litres, def: true},
        'gallons': {ratio: 3.785411784, name: t.measurements.gallons}
    }, 'gallons', 'litres');

    Units.FuelDistanceConsumption = createConverter({
        'litres_per_kilometer': {ratio: 1, name: t.measurements.litres_per_kilometer, def: true},
        'gallons_per_mile': {ratio: 0.42514370743027197, name: t.measurements.gallons_per_mile}
    }, 'gallons_per_mile', 'litres_per_kilometer');

    Units.FuelTimeConsumption = createConverter({
        'litres_per_hour': {ratio: 1, name: t.measurements.litres_per_hour, def: true},
        'gallons_per_hour': {ratio: 0.2641720523581484, name: t.measurements.gallons_per_hour}
    }, 'gallons_per_hour', 'litres_per_hour');

    return Units;
});
