define(['positrace'], function (Positrace) {

    var DayModel = Positrace.Model.extend({

        defaults: {
            date: null //example: '2018-01-29'
        },

        getTimezone: function () {
            console.warn('Override this method!');
            return Base.date.TIMEZONE;
        },

        getDateString: function () {
            var date = this.get('date');
            if (_.isString(date)) {
                return date;
            } else if (Base.date.moment.isMoment(date)) {
                return date.format('YYYY-MM-DD');
            } else {
                return Base.date.moment.tz(date, this.getTimezone()).format('YYYY-MM-DD');
            }
        },

        getAbsoluteDayStartTime: function () {
            return Base.date.moment.tz(this.getDateString(), "Etc/GMT-14").startOf('day');
        },

        getAbsoluteDayEndTime: function () {
            return Base.date.moment.tz(this.getDateString(), "Etc/GMT+12").add(1, 'days').startOf('day');
        },

        getDayStartTime: function (timezone) {
            timezone = timezone || this.getTimezone();
            return Base.date.moment.tz(this.getDateString(), timezone).startOf('day');
        },

        getDayEndTime: function (timezone) {
            return this.getDayStartTime(timezone).add(1, 'days');
        },

        getDayDuration: function () {
            var currentDay = this.getDayStartTime(),
                nextDay = this.getDayEndTime();
            return nextDay.diff(currentDay);
        },

        getViewStartTime: function () {
            var timezone = this.getTimezone();
            var window = this.get('window');
            return Base.date.moment.tz((window && window.start || this.getDayStartTime()), timezone);
        },

        getViewEndTime: function () {
            var timezone = this.getTimezone();
            var window = this.get('window');
            return Base.date.moment.tz((window && window.end || this.getDayEndTime()), timezone);
        },

        getViewDuration: function () {
            return this.getViewEndTime() - this.getViewStartTime();
        },

        getEndTime: function () {
            var dayEndTime = this.getDayEndTime();
            var now = +Date.now();
            var endTimestamp = _.min([now, dayEndTime]);
            return Base.date.moment.tz(endTimestamp, this.getTimezone());
        },

        isTimestampVisible(timestamp) {
            const startTime = this.getViewStartTime();
            const endTime = this.getViewEndTime();

            return timestamp >= startTime && timestamp <= endTime;
        },

        containsInterval: function (intervalStart, intervalEnd) {
            var dayStartTime = this.getDayStartTime(),
                dayEndTime = this.getDayEndTime();
            return !(intervalStart > dayEndTime || intervalEnd < dayStartTime);
        }

    });

    return DayModel;

});
