define(['positrace'], function (Positrace) {

    /**
     This service detect browser state and inject cordova script

     @module application
     @class BrowserService
     @extends Positrace.Object
     */
    var BrowserService = Positrace.Object.extend({

        initialize: function () {
            this.app = this.getOption('app');
            return Positrace.Object.prototype.initialize.apply(this, arguments);
        },

        s4: function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        },

        browserUID: function () {
            var guid, nav, screen;
            nav = window.navigator;
            screen = window.screen;
            guid = nav.mimeTypes.length;
            guid += nav.userAgent.replace(/\D+/g, '');
            guid += nav.plugins.length;
            guid += screen.height || '';
            guid += screen.width || '';
            guid += screen.pixelDepth || '';
            guid += '-' + this.s4() + this.s4();
            return guid;
        },

        getUID: function () {
            var _ref;
            return ((_ref = window.device) != null ? _ref.uuid : void 0) || this.browserUID();
        }

    });

    return BrowserService;

});