define([
    'positrace'
], function (Positrace) {

    const equipmentsStorage = require('../../base/EquipmentsStorage').equipmentsStorage;

    var GroupDecorator = Positrace.Object.extend({

        initialize: function (options) {
            this.model = options.model;
        },

        _getDisplaying: function () {
            var model = this.model;
            if (model.displaying) {
                return model.displaying;
            } else {
                model.displaying = new Positrace.Model({
                    is_visible: true,
                    is_collapsed: false
                });
                model.displaying.on('change:is_visible', function (displayingModel, value, options) {
                    model.trigger('change:is_visible', model, value, options);
                });
                model.displaying.on('change:is_collapsed', function (displayingModel, value, options) {
                    model.trigger('change:is_collapsed', model, value, options);
                });

                return model.displaying;
            }
        },

        getType() {
            return this.model.get('type');
        },

        getIsCollapsed: function () {
            return this._getDisplaying().get('is_collapsed');
        },

        setIsCollapsed: function (value) {
            this._getDisplaying().set('is_collapsed', value);
        },

        toggleIsCollapsed: function () {
            var newIsCollapsed = !this.getIsCollapsed();
            this.setIsCollapsed(newIsCollapsed);
            if (!newIsCollapsed) {
                this.setIsVisible(true);
            }
        },

        getIsVisible: function () {
            return this._getDisplaying().get('is_visible');
        },

        setIsVisible: function (value) {
            this._getDisplaying().set('is_visible', value);
            switch (this.getType()) {
                case 'equipment_group': {
                    _.each(this.getEquipments(), function (equipment) {
                        const decorator = Base.decorator.EquipmentDecorator.decorate(equipment);
                        decorator.setIsVisible(value);
                    });
                    break;
                }
                case 'asset_group': {
                    _.each(this.getAssets(), function (asset) {
                        const decorator = Base.decorator.AssetDecorator.decorate(asset);
                        decorator.setIsVisible(value);
                    });
                    break;
                }
                case 'landmark_group': {
                    _.each(this.getLandmarks(), function (landmark) {
                        const decorator = Base.decorator.LandmarkDecorator.decorate(landmark);
                        decorator.setIsVisible(value);
                    });
                    break;
                }
                case 'geofence_group': {
                    _.each(this.getGeofences(), function (geofence) {
                        const decorator = Base.decorator.GeofenceDecorator.decorate(geofence);
                        decorator.setIsVisible(value);
                    });
                    break;
                }
                default:
            }
        },

        toggleIsVisible: function () {
            var newIsVisible = !this.getIsVisible();
            this.setIsVisible(newIsVisible);
            this.setIsCollapsed(!newIsVisible);
        },

        isUngrouped: function () {
            return !!this.model.get('is_ungrouped');
        },

        _getChildrenFromCollection: function (childrenCollection, options) {
            options = _.extend({includeHidden: false}, options);
            var groupModel = this.model;
            var groupsCollection = LocateApp.request('collection:groups');

            if (this.isUngrouped()) {
                return childrenCollection.filter(function (child) {
                    if (groupModel.get('account_id') !== child.get('account_id')) {
                        return false;
                    }
                    if (!options.includeHidden && !child.isShowOnMap()) {
                        return false;
                    }
                    return _.isNull(child.group.id) || !groupsCollection.get(child.group.id);
                });
            } else {
                return childrenCollection.filter(function (child) {
                    if (groupModel.get('account_id') !== child.get('account_id')) {
                        return false;
                    }
                    if (!options.includeHidden && !child.isShowOnMap()) {
                        return false;
                    }
                    return child.group.id === groupModel.id
                });
            }
        },

        getChildren: function (options) {
            return [].concat(
                this.getEquipments(options),
                this.getAssets(options),
                this.getGeofences(options),
                this.getLandmarks(options)
            );
        },

        //as we have single collection with all user's equipments, this function will provide units of group to create collection for each group
        getEquipments: function (options) {
            return this._getChildrenFromCollection(equipmentsStorage.getCollection(), options);
        },

        getAssets: function (options) {
            return this._getChildrenFromCollection(LocateApp.request('collection:assets'), options);
        },

        getGeofences: function (options) {
            return this._getChildrenFromCollection(LocateApp.request('collection:geofences'), options);
        },

        getLandmarks: function (options) {
            return this._getChildrenFromCollection(LocateApp.request('collection:landmarks'), options);
        }

    });

    GroupDecorator.decorate = function (model) {
        if (!model.decorator) {
            model.decorator = new GroupDecorator({
                model: model
            });
        }
        return model.decorator;
    };

    return GroupDecorator;

});
