const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let geofenceIconsCache = {};

export default {
    getGeofenceURL(color) {
        let cacheKey = `${color}`;
        let url = geofenceIconsCache[cacheKey];
        if (!url) {
            url = geofenceIconsCache[cacheKey] = URL_PREFIX + btoa(this.getGeofenceSVG(color));
        }
        return url;
    },

    getGeofenceSVG(color) {
        const _color = color || '#2B9BF4';

        return `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512">
                <path fill="${_color}" d="M313.9,376.7c41.3-68.5,93.5-164.8,93.5-214.3C407.4,78.9,339.5,11,256,11S104.6,78.9,104.6,162.4
                    c0,49.5,52.2,145.8,93.5,214.3c-49,3.7-160.5,17-160.5,61.1c0,43.4,113.2,63.2,218.5,63.2c105.2,0,218.5-19.8,218.5-63.2
                    C474.5,393.7,362.9,380.4,313.9,376.7z M359.8,165c0,50.2-79.9,176-104.2,213c-24.2-37-104.1-162.9-104.1-213
                    c0-57.4,46.7-104.2,104.1-104.2C313,60.8,359.8,107.5,359.8,165z M256,480.1c-127.9,0-197.6-28-197.6-42.4c0-11.1,46-35,152-41.1
                    c19.9,32.1,35,54.8,37,57.5c2.7,3.6,10.6,7.9,17.3,0c2.1-2.5,17.1-25.4,37-57.5c106,6.1,152,29.9,152,41.1
                    C453.6,452.2,383.9,480.1,256,480.1z"/>
            </svg>
        `;
    },
}
