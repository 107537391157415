define([
    'positrace',
    'base'
], function (Positrace,
             Base) {

    /**
     This service provide a base application layout

     @module application
     @main service
     @class RegionManager
     @extends Positrace.LayoutView
     */
    var RegionManager = Positrace.LayoutView.extend({

        template: _.template(
            '<div id="main-container"></div>'
        ),

        ui: {
            panel: '#main-container'
        },

        regions: {
            panel: '@ui.panel'
        },

        events: {
            'click @ui.mask': 'onClickMask'
        },

        render: function () {
            this.triggerMethod('before:render', this);

            this.isRendered = true;
            this.bindUIElements();

            this.triggerMethod('render', this);

            return this;
        },

        initialize: function (options) {
            this.app = this.getOption('app');
            this.app.reqres.setHandler('layout', this.getLayout, this);
            this.app.commands.setHandler('show', this.showPage, this);
            this.app.rm = this;
            Positrace.LayoutView.prototype.initialize.apply(this, arguments);
        },

        onRender: function () {
        },

        showPage: function (view) {
            this.panel.show(view);
        },

        getLayout: function () {
            return this;
        },

        onDestroy: function () {
        }

    });

    return RegionManager;

});