define([
    'positrace',
    './Date',
    './Units',
    'amdi18n-loader!nls/base_t',
    'amdi18n-loader!nls/address_t',
    './models/index',
    './decorators/index',
    './DownloadFile',
    './ColorsSpec',
    './EventsSpec',
    './Validator'
], function (Positrace,
             Date,
             Units,
             t,
             address_t,
             Models,
             Decorators,
             DownloadFile,
             ColorsSpec,
             EventsSpec,
             Validator) {

    _.t.addDictionary(t);

    return {
        date: Date,
        units: Units,
        decorator: Decorators,
        events: EventsSpec,
        colors: ColorsSpec,
        icons: require('./Icons').default,
        model: _.extend({
            Validator: Validator
        }, Models),

        downloadFile: DownloadFile,

        getURIParameterByName: function (name, url) {
            if (!url) {
                return null;
            }
            url = (url[0] != '?') ? '?' + url : url;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },

        serializeAddress: function (obj) {
            if (!obj) {
                return null;
            }
            var address1 = obj.address1,
                address2 = obj.address2,
                address3 = obj.address3,
                address4 = obj.address4,
                address5 = obj.address5,
                city = obj.city,
                country = obj.country,
                state = obj.state;

            var stateName = state != null ? state.name : void 0,
                countryName = country != null ? country.name : void 0;

            return [address1, address2, address3, address4, address5, city, stateName, countryName].filter(function (v) {
                return !!v;
            }).join(', ');
        },

        formatAddress: function (addr) {
            if (!addr.address1) return '';

            var res = [_([addr.address1, addr.address4]).compact().join(', '),];
            if (addr.address5) {
                res.push(`, ${address_t.labels.address5_mx} ${addr.address5}`);
            }
            if (addr.address2) {
                res.push('<br/>' + addr.address2);
            }
            if (addr.address3) {
                res.push('<br/>' + addr.address3);
            }
            if (addr.city)
                res.push('<br/>' + addr.city + ', ');
            if (addr.state)
                res.push(addr.state.name);
            if (addr.country)
                res.push("<br/>" + addr.country.name);
            if (addr.zip)
                res.push(', ' + "<span>" + addr.zip + "</span>");
            if (addr.phone)
                res.push("<br/><i class='fa fa-phone label-tooltip' title='" + _.t('Phone') + "'></i> " + addr.phone);

            return res.join('');
        },

        /** https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/ **/
        /** https://stackoverflow.com/questions/37717128/javascript-for-downloading-csv-file-doesnt-work-in-firefox **/
        showFile: function (blob, type, name) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([blob], {type: type});

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, name);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = data;
            link.download = name;
            link.click();
            document.body.removeChild(link);
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },

        safelyParseJSON: function (json) {
            // This function cannot be optimised, it's best to
            // keep it small!
            var parsed;

            try {
                parsed = JSON.parse(json)
            } catch (e) {
                // Oh well, but whatever...
            }

            return parsed; // Could be undefined!
        },

        /**
         * Is the interval completely within given range?
         * @param {(moment|number)} intervalStart - Start of interval
         * @param {(moment|number)} intervalEnd - End of interval
         * @param {(moment|number)} rangeStart - Start of range
         * @param {(moment|number)} rangeEnd - End of range
         * @returns {boolean}
         */
        isIntervalWithinRange: function (intervalStart, intervalEnd, rangeStart, rangeEnd) {
            return (intervalStart >= rangeStart && intervalEnd <= rangeEnd);
        },

        /**
         * Is interval crosses a given range?
         * @param {(moment|number)} intervalStart - Start of interval
         * @param {(moment|number)} intervalEnd - End of interval
         * @param {(moment|number)} rangeStart - Start of range
         * @param {(moment|number)} rangeEnd - End of range
         * @returns {boolean}
         */
        isIntervalCrossesRange: function (intervalStart, intervalEnd, rangeStart, rangeEnd) {
            return (intervalStart >= rangeStart && intervalStart <= rangeEnd) || //interval starts in range
                (intervalEnd >= rangeStart && intervalEnd <= rangeEnd) || //interval ends in range
                (intervalStart < rangeStart && intervalEnd > rangeEnd); //interval starts before range and ends after range
        },

        isValidEmail: function (email) {
            var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(email);
        },

        bindEntityTriggers: function (listener, obj, triggers, options) {
            var __slice = [].slice;
            _.each(triggers, function (value, key) {
                listener.listenTo(obj, key, function () {
                    var args;
                    args = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
                    args.unshift(value);
                    if (options) {
                        args.push(options);
                    }
                    listener.triggerMethod.apply(listener, args);
                });
            }, listener);
        },

        unbindEntityTriggers: function (listener, obj, triggers) {
            _.each(triggers, function (value, key) {
                return listener.stopListening(obj, key);
            }, listener);
        },

        proxyEvents: function (listener, obj, prefix) {
            listener.listenTo(obj, 'destroy', function () {
                return listener.stopListening(obj);
            });
            listener.listenTo(obj, 'all', function () {
                var args, event, eventName;
                event = arguments[0], args = 2 <= arguments.length ? [].slice.call(arguments, 1) : [];
                eventName = event;
                if (prefix) {
                    eventName = [prefix, event].join(':');
                }
                return listener.triggerMethod.apply(listener, _.union([eventName], args));
            });
        }
    };
});
