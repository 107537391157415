const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

export default {
    getMapCursorIcon(options) {
        const iconSize = options && options.size || 40;
        const iconType = options.type;
        let direction = this.getCursorDirection(options);

        const iconUrl = iconType === 'timeManager'
            ? this.getMapCursorURL(this.getTimeManagerCursorSVG(direction))
            : this.getMapCursorURL(this.getPinnedTimeManagerCursorSVG(direction));

        return {
            iconUrl: iconUrl,
            iconSize: [iconSize, iconSize],
            iconAnchor: [iconSize / 2, iconSize / 2],
        }
    },

    getMapCursorURL(svg) {
        return URL_PREFIX + btoa(svg);
    },

    getCursorDirection(options) {
        let direction = options && options.direction || 0;

        // Round off direction angle to Nearest 10 to reduce amount of generated pictures
        return direction = Math.round(direction / 10) * 10;
    },

    getPinnedTimeManagerCursorSVG(direction) {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="none">
            <circle cx="256" cy="256" r="256" fill="white"/>
                <path d="M254.516 368.396C254.519 369.44 253.887 370.374 252.927 370.744L253.82 373.099L252.927 370.744L96.3974 431.104C94.1248 431.98 92.0819 429.348 93.4569 427.315L249.481 196.633C250.856 194.6 254.003 195.601 254.01 198.074L254.516 368.396L256.988 368.403L254.516 368.396Z" fill="#8E3B00" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
                <path d="M257.421 372.099C256.445 371.733 255.803 370.785 255.813 369.726L257.465 198.228C257.489 195.765 260.621 194.779 261.992 196.803L418.468 427.695C419.839 429.718 417.819 432.347 415.548 431.494L257.421 372.099Z" fill="#572500" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
                <path d="M259.088 254.282C258.675 253.825 258.447 253.228 258.447 252.607L258.501 12.9092C258.502 10.1266 262.326 9.37893 263.316 11.968L422.494 428.316C423.483 430.905 420.168 432.983 418.319 430.932L259.088 254.282Z" fill="#FF6B00" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
                <path d="M255.474 252.804C255.47 253.426 255.237 254.023 254.82 254.478L94.0629 429.874C92.1983 431.908 88.9015 429.804 89.9102 427.224L252.217 12.0034C253.225 9.42294 257.042 10.1978 257.024 12.9793L255.474 252.804Z" fill="#FFA15E" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
            </svg>
        `;
    },

    getTimeManagerCursorSVG(direction) {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="none">
            <circle cx="256" cy="256" r="256" fill="white"/>
                <path d="M252.927 370.744L96.3974 431.104C94.1248 431.98 92.0819 429.348 93.4569 427.315L249.481 196.633C250.856 194.6 254.003 195.601 254.01 198.074L254.516 368.396C254.519 369.44 253.887 370.374 252.927 370.744Z" fill="#A07300" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
                <path d="M255.813 369.726L257.465 198.228C257.489 195.765 260.621 194.779 261.992 196.803L418.468 427.695C419.839 429.718 417.819 432.347 415.548 431.494L257.421 372.099C256.445 371.733 255.803 370.785 255.813 369.726Z" fill="#905D00" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
                <path d="M258.447 252.607L258.501 12.9092C258.502 10.1266 262.326 9.37898 263.316 11.968L422.494 428.316C423.483 430.905 420.168 432.983 418.319 430.932L259.088 254.282C258.675 253.825 258.447 253.228 258.447 252.607Z" fill="#FFB444" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
                <path d="M254.82 254.478L94.0629 429.874C92.1984 431.908 88.9015 429.804 89.9102 427.224L252.217 12.0034C253.225 9.42294 257.042 10.1978 257.024 12.9793L255.474 252.804C255.47 253.426 255.237 254.023 254.82 254.478Z" fill="#FFDE6E" stroke="black" stroke-width="5" transform="rotate(${direction} 256 256)"/>
            </svg>
        `;
    },
}
