define([
    'positrace'
], function (Positrace) {

    var LanguageService = Positrace.Object.extend({

        defaultLanguage: 'en',

        languages: [
            'en',
            'es',
            'fr',
            'ru',
            'ua'//TODO: change to 'uk' in whole app
        ],

        initialize: function () {
            this.app = this.getOption('app');
        },

        //The same as getLanguage, but changes 'ua' to 'uk'
        getLangAbbr: function () {
            var lang = this.getLanguage();
            if (lang == 'ua') lang = 'uk';
            return lang;
        },

        getLanguage: function () {
            for (var i = 0; i < this.languages.length; i++) {
                var lang = this.languages[i];
                if (location.pathname.indexOf(lang) == 1) {
                    return lang;
                }
            }
            return this.defaultLanguage;
        }

    });

    return LanguageService;

});
