define([
    'amdi18n-loader!nls/auth_t',
    'positrace',
    'base/Module',
    './services/RegionManager',
    'application/service/ModuleLoader',
    './Router',
    './services/SessionService',
    'application/components/UrlBuilder',
    'application/service/LanguageService',
    'application/service/BrowserService',
    'application/service/RememberService'
], function (translations,
             Positrace,
             Base,
             RegionManager,
             ModuleLoader,
             Router,
             SessionService,
             UrlBuilder,
             LanguageService,
             BrowserService,
             RememberService
) {

    var Backbone = require('backbone');
    var Locate = Positrace.locate;
    const SubAccountUrlHelper = require('../application/service/SubAccountUrlHelper').SubAccountUrlHelper;

    var Application = Backbone.Marionette.Application.extend({

        initialize: function (options) {
            var el = this.getOption('el') || 'body';

            this.langService = this.runService('language', LanguageService);
            Positrace.locate.request.extraHeaders['Locate-Accept-Language'] = this.langService.getLanguage();

            //var config = this.getOption('config') || {};
            //    this.ENV = config.env || 'develop';
            //    this.version = config.version;
            //    this.build = config.build;
            this.apiKey = "f2d2dbf8e7f3487f5b61104aada57bbe";
            var apiUrl = this.apiUrl = location.protocol + '//' + location.host;//config.apiHost;
            if (!apiUrl) {
                throw new Error('Undefined apiUrl');
            }
            _.t.addDictionary(translations);
            this.urlBuilder = new UrlBuilder;
            this.urlBuilder.setConfig({
                host: apiUrl
            });
            Locate.request.urlBuilder.setConfig({
                host: this.apiUrl
            });
            this.runService('browser', BrowserService);
            this.runService('regionManager', RegionManager, {
                el: el
            });
            this.runService('remember', RememberService);
            this.runService('module', ModuleLoader);

            this.runService('router', Router);
        },

        getSearchParams: function () {
            if (!this.searchParams) {
                var str = window.location.search;
                var objURL = {};
                str.replace(
                    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                    function ($0, $1, $2, $3) {
                        objURL[$1] = $3;
                    }
                );
                this.searchParams = objURL;
            }
            return this.searchParams;
        },

        isUrlPasswordEdit: function () {
            return /password\/edit/.test(window.location.pathname)
        },

        isUrlPasswordNew: function () {
            return /password\/new/.test(window.location.pathname);
        },

        getStartUrl: function () {
            var params = this.getSearchParams();
            var resetPasswordToken = params.reset_password_token;
            if (this.isUrlPasswordEdit() && resetPasswordToken) {
                return "#auth/resetPassword";
            }
            if (this.isUrlPasswordNew()) {
                return "#auth/forgotPassword"
            }
            var code = params.code;
            if (code) {
                return "#auth/googleAuth";
            }

            return "#auth/login";
        },

        savePreviousHash() {
            const hash = window.location.hash;
            if (!hash || /auth\//.test(hash)) {
                // Skip #auth/* links
                return;
            }

            window.sessionStorage.setItem('previousHash', hash);
        },

        getPreviousHash() {
            return window.sessionStorage.getItem('previousHash');
        },

        getUrlAccountCode: function () {
            return SubAccountUrlHelper.getAccountCodeFromUrl();
        },

        getAccountCodeBasedUrl: function (accountCode) {
            return SubAccountUrlHelper.getAccountCodeBasedUrl({
                accountCode,
                languageCode: this.langService.getLanguage()
            });
        },

        reload: function () {
            this.replaceRoute('');
            window.location.href = window.location.protocol + '//' + window.location.host + '/' + this.langService.getLanguage();
        },

        runService: function (name, constructor, options) {
            var service;
            options = options || {};
            _.extend(options, {
                app: this
            });
            service = new constructor(options);
            this.reqres.setHandler(name, function () {
                return service;
            });
            return service;
        },

        stopService: function (name) {
            var service;
            service = this.request(name);
            this.reqres.removeHandler(name);
            if (typeof service.destroy === "function") {
                service.destroy();
            }
            return service;
        },

        restoreSession: function () {
            var uid = this.request('browser').getUID();
            return this.sessionInitializer
                .getDeviceToken(uid, this.apiKey)
                .then(_.bind(function (deviceToken) {
                    this.sessionInitializer.restoreSession(deviceToken);
                }, this));
        },

        remember: function () {
            var service;
            service = this.request('remember');
            return service.val.apply(service, arguments);
        },

        start: function () {
            this.savePreviousHash();
            this.request('remember')
                .initLocalStorage()
                .then(() => {
                    this.sessionInitializer = this.runService('sessionService', SessionService);
                    return this.sessionInitializer.getSession();
                })
                .then((session) => {
                    this.session = session;
                    var regionManager = this.request('regionManager');
                    regionManager.render();

                    this.restoreSession().then(() => {
                        this.request('router').start(this.getStartUrl());
                    });
                    Backbone.Marionette.Application.prototype.start.apply(this, arguments);
                });
        },

        navigate: function (path, options) {
            var router;
            path = path || '';
            options = options || {};
            options = _.defaults(options, {
                trigger: true
            });
            router = this.request('router');
            return router.navigate(path, options);
        },

        showRoute: function (route) {
            this.navigate(route, {trigger: false});
        },

        redirectRoute: function (route) {
            if (Backbone.history.fragment == route) {
                Backbone.history.loadUrl(Backbone.history.fragment);
            } else {
                this.navigate(route, {trigger: true});
            }
        },

        // replace current route without triggering events
        replaceRoute: function (route) {
            this.navigate(route, {trigger: false, replace: true});
        }

    });

    return Application;

});
