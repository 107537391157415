define([
    'positrace'
], function (Positrace) {

    var DriverDecorator = Positrace.Object.extend({

        initialize: function (options) {
            this.model = options.model;
        },

        isNew: function () {
            return this.model.isNew();
        },

        getEmailPopupMessage: function () {
            if (this.model.user.getPendingEmail()) {
                if (!this.model.user.isEmailConfirmed()) {
                    return _.t('Email address {{email}} is not confirmed and is waiting for confirmation of changing it to {{new_email}}', {
                        email: this.model.user.getEmail(),
                        new_email: this.model.user.getPendingEmail()
                    });
                } else {
                    return _.t('Pending verification email address change to {{new_email}}', {
                        new_email: this.model.user.getPendingEmail()
                    });
                }
            }
            if (this.model.user.isEmailConfirmed()) return '';
            if (this.model.user.isEmailBlocked()) {
                return _.t('Email address blocked by the administrator.');
            } else {
                return _.t('The email address is not confirmed.');
            }
        },

        getOldEmailPopupMessage: function () {
            if (this.model.user.isEmailConfirmed()) return '';
            if (this.model.user.isEmailBlocked()) {
                return _.t('Email address blocked by the administrator.');
            } else {
                return _.t('The email address is not confirmed.');
            }
        },

        getNewEmailPopupMessage: function () {
            if (this.model.user.getPendingEmail()) {
                return _.t('Pending verification email address change to {{new_email}}', {
                    new_email: this.model.user.getPendingEmail()
                });
            }
        },

        getFullName: function () {
            return this.model.getFullName();
        },

        getDriverKey() {
            return this.model.get('commutator')?.unique_id || this.model.getIbid();
        },

        /** @deprecated Use getDriverKey instead */
        getIbid: function () {
            return this.model.getIbid();
        },

        getIbidId: function () {
            return parseInt(this.model.get('driver_key_id')) || undefined;
        },

        getJobTitle: function () {
            return this.model.get('job_title');
        },

        getEmail: function () {
            return this.model.user.getEmail();
        },

        getPendingEmail: function () {
            return this.model.user.getPendingEmail();
        },

        isEmailConfirmed: function () {
            return this.model.user.isEmailConfirmed();
        },

        isEmailBlocked: function () {
            return this.model.user.isEmailBlocked();
        },

        getFullPhone: function () {
            return this.model.user.getPhone();
        },

        getStaticSmsCode: function () {
            return this.model.get('static_sms_code');
        },

        getPhoneCountryCode: function () {
            return this.model.user.get('phone_country_code');
        },

        getPhoneNumber: function () {
            return this.model.user.get('phone_number');
        },

        getPhoneExtension: function () {
            return this.model.user.get('extension');
        },

        getSSN: function () {
            return this.model.get('ssn') || '';
        },

        getDriverLicenseStateName: function () {
            var driverLicenseState = this.model.get('driver_license_state');
            return driverLicenseState ? driverLicenseState.name : '';
        },

        getDriverLicenseStateId: function () {
            var driverLicenseState = this.model.get('driver_license_state');
            return driverLicenseState ? driverLicenseState.id : null;
        },

        getDriverLicenseNumber: function () {
            return this.model.get('driver_license_number') || ''
        },

        getApolloRuleSetId() {
            const ruleSet = this.model.get('apollo_rule_set');
            return ruleSet && ruleSet.id || undefined;
        },

        getApolloExemptionId() {
            const exemptionId = this.model.get('apollo_exemption_id');
            return exemptionId && exemptionId.id || undefined;
        },

        getApolloRuleSetName() {
            const ruleSet = this.model.get('apollo_rule_set');
            return ruleSet && ruleSet.name || undefined;
        },

        getApolloJurisdictionId() {
            const jurisdiction = this.model.get('apollo_jurisdiction');
            return jurisdiction && jurisdiction.id || undefined;
        },

        isCanadaApolloJurisdiction() {
            const jurisdiction = this.model.get('apollo_jurisdiction');
            return jurisdiction && jurisdiction.country_code === 'CA';
        },

        getApolloJurisdictionName() {
            const ruleSet = this.model.get('apollo_jurisdiction');
            return ruleSet && ruleSet.name || undefined;
        },

        getApolloPeriodStartingTimeId() {
            const periodStartingTimeObj = this.model.get('apollo_period_starting_time');
            return periodStartingTimeObj && periodStartingTimeObj.id || undefined;
        },

        isApolloEldPasswordSet() {
            return this.model.get('apollo_eld_password_set');
        },

        getDriverCycleSettingId: function () {
            return this.model.get('driver_cycle_setting_id');
        },

        getDriverCycleSettingName: function () {
            var driverCycleSetting = this.model.get('driver_cycle_setting');
            return driverCycleSetting ? driverCycleSetting.name : '';
        },

        getFormattedBirthDate: function () {
            var birthDate = this.model.get('birth_date');
            return birthDate ? Base.date.Format.date(birthDate) : '';
        },

        getFormattedHireDate: function () {
            var hireDate = this.model.get('hire_date');
            return hireDate ? Base.date.Format.date(hireDate) : '';
        },

        getFormattedFireDate: function () {
            var fireDate = this.model.get('fire_date');
            return fireDate ? Base.date.Format.date(fireDate) : '';
        },

        getHomeTerminalId: function () {
            return this.model.homeTerminal.id;
        },

        getHomeTerminalName: function () {
            return this.model.homeTerminal.getName();
        },

        getHomeTerminalAddress: function () {
            return this.model.getHomeTerminalAddress();
        },

        getHomeTerminalTimeZone: function () {
            return this.model.getHomeTerminalTimeZone();
        },

        getAvailableHomeTerminalsIds: function () {
            return _(this.model.get('available_home_terminals')).pluck('id');
        },

        getAvailableMotorCarriersIds: function () {
            return _(this.model.get('available_motor_carriers')).pluck('id');
        },

        getAvailableMotorCarriersHtml: function () {
            return _(this.model.get('available_motor_carriers')).map(function (motorCarrier) {
                return motorCarrier.usdot_number + ' - ' + motorCarrier.name;
            }).join(', ');
        },

        getEldMultidayBasis: function () {
            return this.model.get('eld_multiday_basis');
        },

        getEldDrivingCategoryIds: function () {
            return this.model.get('eld_driving_category_ids');
        },

        getCommutatorId() {
            return this.model.get('commutator')?.id;
        },

        getAvatarUrl: function () {
            return this.model.getPhotoUrl() || '/assets/attachment/no_avatar.png';
        }

    });

    DriverDecorator.decorate = function (model) {
        if (!model.decorator) {
            model.decorator = new DriverDecorator({
                model: model
            });
        }
        return model.decorator;
    };

    return DriverDecorator;

});
