export class SubAccountUrlHelper {
    static getAccountCodeFromUrl() {
        const re = /([^\/]*)-locate/ig;
        const result = re.exec(window.location.hostname);
        return result ? result[1] : null;
    }

    static getAccountCodeBasedUrl({accountCode, languageCode}) {
        const locateSubdomain = this.getLocateSubdomain();

        const regSubAccountSubdomain = new RegExp(`([^\\/]*)-${locateSubdomain}\\.`, 'ig');
        const regMainAccountSubdomain = `${locateSubdomain}.`;
        const rootDomain =
            window.location.hostname
                .replace(regSubAccountSubdomain, '')
                .replace(regMainAccountSubdomain, '')

        return '' +
            (window.location.protocol ? window.location.protocol + '//' : '') +
            (accountCode ? accountCode.toLowerCase() + '-' : '') +
            `${locateSubdomain}.${rootDomain}` +
            (window.location.port ? ':' + window.location.port : '')
            + `/${languageCode}/`;
    }

    static getLocateSubdomain() {
        return window.gon?.locate_subdomain || 'locate';
    }
}
