require('lib/modernizr-custom');

var languages = [
    {
        locale: 'en',
        message: 'Your browser seems out-of-date. To ensure all PosiTrace\'s features work properly - ',
        link_name: 'update your browser now!',
        close_btn_title: 'Close'
    },
    {
        locale: 'es',
        message: 'Parece que la versión de su navegador no es actual. Para garantizar que todas las características de PosiTrace funcionan correctamente - ',
        link_name: 'actualise su navegador ahora!',
        close_btn_title: 'Cerrar'
    },
    {
        locale: 'fr',
        message: 'Votre navigateur semble obsolète. Pour assurer le bon fonctionnement de PosiTrace, ',
        link_name: 'mettez à jour votre navigateur maintenant.',
        close_btn_title: 'Fermer'
    },
    {
        locale: 'ru',
        message: 'Ваш браузер устарел. Чтобы убедиться, что все PosiTrace функции работают должным образом - ',
        link_name: 'обновите Ваш браузер!',
        close_btn_title: 'Закрыть'
    },
    {
        locale: 'ua',
        message: 'Ваш браузер застарів. Щоб переконатися, що всі PosiTrace функції працюють належним чином - ',
        link_name: 'поновіть Ваш браузер!',
        close_btn_title: 'Закрити'
    }
];

var updateBrowser = function() {
    var locale = window.location.pathname.slice(1, 3);
    var alert = null;
    for (var i = 0; i < languages.length; i++) {
        if (languages[i].locale === locale) {
            alert = languages[i];
            break;
        }
    }
    var alertMsg = "<div id=\"outdated\"><p>" +
        alert.message +
        "<a id=\"btnUpdateBrowser\" href=\"http://outdatedbrowser.com/" + locale + "/\">" +
        alert.link_name +
        "</a></p><p class=\"last\"><a href=\"#\" id=\"btnCloseUpdateBrowser\">&times;</a></p></div>";
    var body = document.getElementsByTagName('body')[0];
    body.insertAdjacentHTML("afterbegin", alertMsg);
    var outdated = document.getElementById("outdated");
    var btnClose = document.getElementById("btnCloseUpdateBrowser");
    btnClose.setAttribute('title', alert.close_btn_title);
    btnClose.onmousedown = function () {
        outdated.style.display = 'none';
        return false;
    };
};

if (
    Modernizr.csstransitions &&
    Modernizr.flexbox &&
    Modernizr.flexwrap &&
    Modernizr.websockets
) {
    // supported
    console.log('Your browser is supported');

} else {
    // not-supported
    $(document).ready(function () {
        updateBrowser();
    });
    console.log('Your browser is not supported');
}
