define([
    'positrace'
], function (Positrace) {

    var LandmarkDecorator = Positrace.Object.extend({

        initialize: function () {
            return this.model = this.getOption('model');
        },

        _getDisplaying() {
            const model = this.model;
            if (model.displaying) {
                return model.displaying;
            } else {
                model.displaying = new Positrace.Model({
                    is_visible: true
                });
                model.displaying.on('change:is_visible', function (displayingModel, value, options) {
                    model.trigger('change:is_visible', model, value, options);
                });

                return model.displaying;
            }
        },

        getIsVisible() {
            return this._getDisplaying().get('is_visible');
        },

        setIsVisible(value) {
            this._getDisplaying().set('is_visible', value);
        },

        getIcon: function () {
            return Base.icons.getLandmarkURL(this.model.getHEXColor());
        },

        getMapIcon: function () {
            let color = this.model.getHEXColor();
            let opacity = this.isShowOnMap() ? 0.96 : 0.56;
            return Base.icons.getMapLandmarkIcon(color, opacity);
        },

        isShowOnMap: function () {
            return this.model.isShowOnMap();
        },

        getAddress: function () {
            var address;
            address = this.model.get('address');
            if (address) {
                return address;
            } else {
                return "can not be defined"; //TODO: add translation
            }
        },

        getDraftAddress: function () {
            var address = this.model.get('address');
            if (address) {
                return address;
            } else {
                return null;
            }
        },

        getPosition: function () {
            let coordinate = this.model.getCoordinate();
            if (coordinate) {
                return Base.geography.createLatLng(coordinate);
            }
        },

        getRadius: function () {
            return this.model.get('radius');
        },

        getName: function () {
            return this.model.get("name") || '';
        },

        getDescription: function () {
            return this.model.get('description') || '';
        },

        lat: function () {
            return this.model.get('latitude');
        },

        lng: function () {
            return this.model.get('longitude');
        },

        getReducedLatLng: function () {
            var lat = this.lat();
            var lng = this.lng();
            if (!lat && !lng) {
                return null;
            } else {
                return Base.units.round(lat, 6) + ', ' + Base.units.round(lng, 6);
            }
        },

        getRGBColor: function () {
            return this.model.getHEXColor();
        },

    });

    LandmarkDecorator.decorate = function (model) {
        if (!model.decorator) {
            model.decorator = new LandmarkDecorator({
                model: model
            });
        }
        return model.decorator;
    };

    return LandmarkDecorator;

});