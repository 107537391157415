const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let equipmentIconsCache = {};
let equipmentTypeIconsCache = {};

const EquipmentTypeIcons = {
    atv_motorcycle: require('!svg-inline-loader!./equipment/atv_motorcycle.svg'),
    boat: require('!svg-inline-loader!./equipment/boat.svg'),
    bulldozer: require('!svg-inline-loader!./equipment/bulldozer.svg'),
    bus: require('!svg-inline-loader!./equipment/bus.svg'),
    car: require('!svg-inline-loader!./equipment/car.svg'),
    concrete_mixer: require('!svg-inline-loader!./equipment/concrete_mixer.svg'),
    dry_box: require('!svg-inline-loader!./equipment/dry_box.svg'),
    dump_truck: require('!svg-inline-loader!./equipment/dump_truck.svg'),
    electronics: require('!svg-inline-loader!./equipment/electronics.svg'),
    excavator: require('!svg-inline-loader!./equipment/excavator.svg'),
    flatbed: require('!svg-inline-loader!./equipment/flatbed.svg'),
    forklift: require('!svg-inline-loader!./equipment/forklift.svg'),
    pickup: require('!svg-inline-loader!./equipment/pickup.svg'),
    reefer: require('!svg-inline-loader!./equipment/reefer.svg'),
    scooter: require('!svg-inline-loader!./equipment/scooter.svg'),
    suv: require('!svg-inline-loader!./equipment/suv.svg'),
    tow_truck: require('!svg-inline-loader!./equipment/tow_truck.svg'),
    tractor: require('!svg-inline-loader!./equipment/tractor.svg'),
    trailer: require('!svg-inline-loader!./equipment/trailer.svg'),
    truck: require('!svg-inline-loader!./equipment/truck.svg'),
    van: require('!svg-inline-loader!./equipment/van.svg'),
    grader: require('!svg-inline-loader!./equipment/grader.svg'),
    paver: require('!svg-inline-loader!./equipment/paver.svg'),
    frontend_loader: require('!svg-inline-loader!./equipment/frontend_loader.svg'),
    compaction_roller: require('!svg-inline-loader!./equipment/compaction_roller.svg'),
    skid_steer: require('!svg-inline-loader!./equipment/skid_steer.svg')
};

export default {
    getEquipmentTypeURL(type, color) {
        if (!type) return '';
        let cacheKey = `${type}`;
        let url = equipmentTypeIconsCache[cacheKey];
        if (!url) {
            const icon = type && EquipmentTypeIcons[type] || `<circle id="circle" cx="8" cy="8" r="4"/>`;
            const iconFill = color || '#333333';
            const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style = "fill: ${iconFill};">${icon}</svg>`;
            url = equipmentTypeIconsCache[cacheKey] = `${URL_PREFIX}${btoa(svg)}`;
        }
        return url;
    },

    getEquipmentURL(color, type, state, hasAsset) {
        let cacheKey = `${color}:${type}:${state}:${hasAsset}`;
        let url = equipmentIconsCache[cacheKey];
        if (!url) {
            url = equipmentIconsCache[cacheKey] = URL_PREFIX + btoa(this.getEquipmentSVG(color, type, state, hasAsset));
        }
        return url;
    },

    getEquipmentStateIcon(state) {
        let circle = `<circle cx="7.5" cy="7.5" r="7.5" style="fill: #fff;"/>`;
        switch (state) {
            case 'offline':
                return `${circle}<rect x="2.5" y="5.5" width="10" height="4" style="fill: #555;"/>`;
            case 'play':
                return `${circle}<polygon points="13 7.5 4 12 4 3 13 7.5" style="fill: #009e00;"/>`;
            case 'pause':
                return `${circle}
                          <rect x="4" y="4" width="3" height="7" style="fill: #ffb400;"/>
                          <rect x="8" y="4" width="3" height="7" style="fill: #ffb400;"/>
                    `;
            case 'stop':
                return `${circle}<rect x="4" y="4" width="7" height="7" style="fill: #024f9a;"/>`;
            default:
                return '';
        }
    },

    getEquipmentAssetIndicatorIcon() {
        return `
            <svg height="16" width="16" x="23" y="0" fill="none">
                <rect x="1.375" y="1.375" width="13.25" height="13.25" rx="1.625" fill="#4E4E4E" stroke="white" stroke-width="0.75"/>
                <g clip-path="url(#clip0_407_1746)">
                    <path d="M4.32083 6.1L8 8.22917L11.6542 6.1125" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 12.0042V8.22501" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.13776 4.03345L4.91276 5.26678C4.40859 5.54595 3.99609 6.24595 3.99609 6.82095V9.17511C3.99609 9.75011 4.40859 10.4501 4.91276 10.7293L7.13776 11.9668C7.61276 12.2293 8.39193 12.2293 8.86693 11.9668L11.0919 10.7293C11.5961 10.4501 12.0086 9.75011 12.0086 9.17511V6.82095C12.0086 6.24595 11.5961 5.54595 11.0919 5.26678L8.86693 4.02928C8.38776 3.76678 7.61276 3.76678 7.13776 4.03345Z" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_407_1746">
                        <rect width="10" height="10" fill="white" transform="translate(3 3)"/>
                    </clipPath>
                </defs>
            </svg>
        `
    },

    getEquipmentSVG(color = '#777', type, state, hasAsset) {
        let stateIcon = this.getEquipmentStateIcon(state);
        let assetIndicatorIcon = hasAsset ? this.getEquipmentAssetIndicatorIcon() : '';
        let viewBox = stateIcon ? "0 0 38 39.5" : "6.5 6.5 25 33";
        let iconFill = Base.colors.getContrastColor(color);
        let typeIcon = type && EquipmentTypeIcons[type] || `<circle id="circle" cx="8" cy="8" r="4" style = "fill: ${iconFill};"/>`;
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}">
              <defs>
                <style>
                  #equ_pin {fill: ${color};}
                  #circle {fill: #fff;}
                </style>
              </defs>
              <path id="equ_pin" d="M19,6.5a12.43,12.43,0,0,0-5.6,23.56L19,39.5l5.6-9.44A12.43,12.43,0,0,0,19,6.5Z"/>
              <svg height="16" width="16" x="11" y="11" style = "fill: ${iconFill};">
                ${typeIcon}
              </svg>
              ${stateIcon}
              ${assetIndicatorIcon}
            </svg>
        `;
    },

    getMapEquipmentIcon(color, type, state, hasAsset) {
        return {
            iconUrl: this.getEquipmentURL(color, type, state, hasAsset),
            iconSize: [38, 39.5],
            iconAnchor: [19, 39.5],
        }
    },
}
