define(['positrace'], function (Positrace) {

    /**
     This service allows to load app modules and provides access interface to them


     @module application
     @main service
     @class ModuleLoader
     @extends Positrace.Object
     */
    var ModuleLoader = Positrace.Object.extend({

        /**
         @method initialize
         @constructor
         @param {Object} [options]
         @param {Application} [options.app] - application instance
         */

        initialize: function () {

            /**
             Application instance
             @property {Application} app
             */
            this.app = this.getOption('app');
            if (!this.app) {
                throw new Error('ModuleLoader require link to application');
            }
            //this.prototype.initialize.apply(this, arguments);
        },


        /**
         This method returns module if it loaded

         @method getModule
         @param {String} name - module name
         @return {Positrace.Module} returns module or undefined
         */

        getModule: function (name) {
            var moduleName;
            moduleName = this.getModuleName(name);
            return this.app.request(moduleName);
        },


        /**
         Normolize a module name.
         @example
         loader.getModuleName('auth') => 'module:auth'
         @method getModuleName
         @param {String} module name
         @return {String}
         */

        getModuleName: function (name) {
            return "module:" + name;
        },


        /**
         This method returns module by request.

         If module didn't load yet. He will load
         If module load already he will return from cache
         @method get
         @param {String} module name
         @param {Function} module constructor. This is private param
         @param {Object} constructor options
         @return {Promise} return promise
         */

        get: function (name, constructor, options) {
            var defer, module;
            defer = $.Deferred();
            module = this.getModule(name);
            if (module) {
                defer.resolve(module);
            }
            if (!module && _.isFunction(constructor)) {
                module = this.register(name, constructor, options);
                if (module) {
                    defer.resolve(module);
                }
            } else if (!module) {
                this.load(name, constructor || {}).then(defer.resolve, defer.reject);
            }
            return defer.promise();
        },

        onError: function (error, modulename, options) {
            console.error("Module " + modulename + " wasn\'t load.", error);
            this.app.execute('devreport', {
                place: 'ModuleLoader',
                message: error.message,
                error: error
            });
            return this.app.execute('show:crush', {
                title: _.t('Critical error'),
                message: _.t("Sorry, there was a critical error in the application.", {
                    modulename: _.escape(modulename)
                })
            });
        },


        /**
         This method try load module by name.

         @method load
         @param name {String} module name
         @param options {Object} constructor options
         @private
         @return {Promise} return promise
         */

        load: function (name, options) {
            var defer, request, fnDone, fnFail;
            options = options || {};
            defer = $.Deferred();

            fnDone = (function (_this) {
                return function (module) {
                    var moduleConstructor = module && module.default;
                    return defer.resolve(_this._initModule(name, moduleConstructor, options));
                };
            })(this);

            fnFail = (function (_this) {
                return function (error) {
                    _this.triggerMethod('error', error, name, options);
                    return defer.reject(error);
                };
            })(this);

            if (request = this.requestModuleByName(name)) {
                request.then(fnDone, fnFail);
            } else {
                fnFail(new Error('Unknown module ' + name));
            }
            return defer.promise();
        },

        importAngularCustomComponents() {
            // Import ANGULAR custom components
            return import(/* webpackChunkName: "angular-custom-components" */'angular/custom-components-integrator.ts');
        },

        requestModuleByName: function (name) {
            switch (name) {
                case 'account':
                    return import(
                        /* webpackChunkName: "account" */
                        '../../modules/account'
                        );
                case 'auth':
                    return import(
                        /* webpackChunkName: "auth" */
                        '../../modules/auth'
                        );
                case 'blockedAccount':
                    return import(
                        /* webpackChunkName: "blockedAccount" */
                        '../../modules/blockedAccount'
                        );
                case 'dashboard':
                    return import(
                        /* webpackChunkName: "dashboard" */
                        '../../modules/dashboard'
                        );
                case 'dispatch':
                    return import(
                        /* webpackChunkName: "dispatch" */
                        '../../modules/dispatch'
                        );
                // case 'drivers':
                //     return import(
                //         /* webpackChunkName: "drivers" */
                //         '../../modules/drivers'
                //         );
                case 'dvir':
                    return import(
                        /* webpackChunkName: "dvir" */
                        '../../modules/dvir'
                        );
                case 'fmcsa':
                    return import(
                        /* webpackChunkName: "fmcsa" */
                        '../../modules/fmcsa'
                        );
                case 'geofences':
                    return import(
                        /* webpackChunkName: "geofences" */
                        '../../modules/geofences'
                        );
                case 'hardwareAlerts':
                    return import(
                        /* webpackChunkName: "hardwareAlerts" */
                        '../../modules/hardwareAlerts'
                        );
                case 'history':
                    return import(
                        /* webpackChunkName: "history" */
                        '../../modules/history'
                        );
                case 'hos':
                    return import(
                        /* webpackChunkName: "hos" */
                        '../../modules/hos'
                        );
                case 'landmarks':
                    return import(
                        /* webpackChunkName: "landmarks" */
                        '../../modules/landmarks'
                        );
                case 'map':
                    return Promise.all([
                        this.importAngularCustomComponents()
                    ]).then(() => import(/* webpackChunkName: "map" */'../../modules/map'));
                case 'print':
                    return import(
                        /* webpackChunkName: "print" */
                        '../../modules/print'
                        );

                case 'Alert':
                    return import(
                        /* webpackChunkName: "Alert" */
                        '../../modules/alert'
                        );
                case 'Billing':
                    return import(
                        /* webpackChunkName: "Billing" */
                        '../../modules/billing'
                        );
                case 'Maintenance':
                    if (this.app.session.user.adjustment.get('old_maintenance')) {
                        return import(
                            /* webpackChunkName: "Maintenance" */
                            '../../modules/maintenance'
                            );
                    } else {
                        return Promise.reject();
                    }
                case 'Permissions':
                    return import(
                        /* webpackChunkName: "Permissions" */
                        '../../modules/permissions'
                        );
                case 'Reports':
                    return import(
                        /* webpackChunkName: "Reports" */
                        '../../modules/reports'
                        );
            }
        },

        /**
         This method tries to create a module instance
         if it still haven't been created
         and register module like an application service

         @method register
         @param {String} name - module name
         @param {Function} moduleConstructor - module constructor.
          This is private param

         @param {Object} options -  constructor options
         @return {Positrace.Module} return module
         */

        register: function (name, moduleConstructor, options) {
            var module;
            module = this.getModule(name);
            if (!module) {
                module = this._initModule(name, moduleConstructor, options);
            }
            return module;
        },

        _initModule: function (name, module, options) {
            options = options || {};
            options = _.defaults({}, options, {
                app: this.app,
                name: this.getModuleName(name),
                routePrefix: name
            });
            return this.app.runService(options.name, module, options);
        },

        destroyModule: function (name) {
            var module, moduleName;
            moduleName = this.getModuleName(name);
            if (!(module = this.app.request(moduleName))) {
                return;
            }
            this.app.stopService(moduleName);
            module.destroy();
            return module = null;
        }
    });

    return ModuleLoader;

});
