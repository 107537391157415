define([], function () {

    const Positrace = require('positrace');
    const t = require('amdi18n-loader!nls/map_t');

    //@formatter:off
    var eventTypesInitialData = [
        {id: 1,  stateName: t.trip,                     name: t.ignition_on,                labelClass: 'label-success',   color: '#42B555'},
        {id: 2,  stateName: t.trip,                     name: t.ignition_off,               labelClass: 'label-important', color: '#F4558B'},
        {id: 3,  stateName: t.valid_fix,                name: t.valid_fix,                  labelClass: 'label-important'},
        {id: 4,  stateName: t.invalid_fix,              name: t.invalid_fix,                labelClass: 'label-important'},
        {id: 5,  stateName: t.speeding,                 name: t.speeding_start,             labelClass: 'label-important', color: '#9C27B0'},
        {id: 6,  stateName: t.speeding,                 name: t.speeding_end,               labelClass: 'label-important', color: '#EE82EE'},
        {id: 7,  stateName: t.towing,                   name: t.towing_start,               labelClass: 'label-danger',    color: '#FD4D16'},
        {id: 8,  stateName: t.idling,                   name: t.idling_start,               labelClass: 'label-warning',   color: '#FF9800'},
        {id: 9,  stateName: t.idling,                   name: t.idling_end,                 labelClass: 'label-warning',   color: '#FFC507'},
        {id: 10, stateName: t.acceleration,             name: t.acceleration,               labelClass: 'label-success',   color: '#426D84'},
        {id: 12, stateName: t.deceleration,             name: t.deceleration,               labelClass: 'label-info',      color: '#009688'},
        {id: 15, stateName: t.pto,                      name: t.pto_start,                  labelClass: 'label-info',      color: '#054AAE'},
        {id: 16, stateName: t.pto,                      name: t.pto_end,                    labelClass: 'label-info',      color: '#7196F2'},
        {id: 17, stateName: t.towing,                   name: t.towing_end,                 labelClass: 'label-danger',    color: '#FF9C6E'},
        {id: 18, stateName: t.alarm_on,                 name: t.alarm_on,                   labelClass: 'label-danger',    color: '#E82228'},
        {id: 21, stateName: t.harsh_turn,               name: t.harsh_turn,                 labelClass: 'label-warning',   color: '#FF9800'},
        {id: 22, stateName: t.crash_detection,          name: t.crash_detection,            labelClass: 'label-important', color: '#B21319'},
        {id: 23, stateName: t.motion_on,                name: t.motion_on,                  labelClass: 'label-important'},
        {id: 24, stateName: t.motion_off,               name: t.motion_off,                 labelClass: 'label-important'},
        {id: 25, stateName: t.text_message,             name: t.text_message,               labelClass: 'label-important'},
        {id: 26, stateName: t.canned_response,          name: t.canned_response,            labelClass: 'label-important'},
        {id: 27, stateName: t.live,                     name: t.live,                       labelClass: 'label-important'},
        {id: 29, stateName: t.power_restored,           name: t.power_restored,             labelClass: 'label-important', hidden: true},
        {id: 30, stateName: t.gps_lost,                 name: t.gps_lost,                   labelClass: 'label-important', hidden: true},
        {id: 31, stateName: t.went_to_sleep_mode,       name: t.went_to_sleep_mode,         labelClass: 'label-important'},
        {id: 32, stateName: t.antenna_connection_lost,  name: t.antenna_connection_lost,    labelClass: 'label-important'},
        {id: 33, stateName: t.main_power_source_lost,   name: t.main_power_source_lost,     labelClass: 'label-important'},
        {id: 34, stateName: t.garmin_connection_lost,   name: t.garmin_connection_lost,     labelClass: 'label-important'},
        {id: 35, stateName: t.temperature_out_of_range, name: t.temperature_out_of_range,   labelClass: 'label-important'},
        {id: 36, stateName: t.temperature_normalized,   name: t.temperature_normalized,     labelClass: 'label-important'},
        {id: 37, stateName: t.gps_found,                name: t.gps_found,                  labelClass: 'label-important', hidden: true},
        {id: 38, stateName: t.in_coverage,              name: t.in_coverage,                labelClass: 'label-important', hidden: true},
        {id: 39, stateName: t.no_coverage,              name: t.no_coverage,                labelClass: 'label-important', hidden: true},
        {id: 40, stateName: t.sim_removed,              name: t.sim_removed,                labelClass: 'label-important', hidden: true},
        {id: 41, stateName: t.fill_up,                  name: t.fill_up_start,              labelClass: 'label-success',   color: '#006400'},
        {id: 42, stateName: t.fill_up,                  name: t.fill_up_end,                labelClass: 'label-success',   color: '#42B555'},
        {id: 43, stateName: t.drain,                    name: t.drain_start,                labelClass: 'label-danger',    color: '#CD853F'},
        {id: 44, stateName: t.drain,                    name: t.drain_end,                  labelClass: 'label-danger',    color: '#BC8F8F'},
        {id: 45, stateName: t.jamming,                  name: t.jamming,                    labelClass: 'label-danger',    color: '#E82228'},
        {id: 49, stateName: t.seat_belt_unbuckled,      name: t.seat_belt_unbuckled,        labelClass: 'label-warning',   color: '#6D0C03'},
        {id: 50, stateName: t.seat_belt_unbuckled,      name: t.seat_belt_buckled,          labelClass: 'label-warning',   color: '#795548'},
        {id: 55, stateName: t.low_external_voltage,     name: t.low_external_voltage,       labelClass: 'label-warning',   color: '#B21319'},
        {id: 56, stateName: t.low_external_voltage,     name: t.external_voltage_normalized,labelClass: 'label-success',   color: '#FF9C6E'},
        {id: 60, stateName: t.media,                    name: t.media_start,                labelClass: 'label-important'},
        {id: 61, stateName: t.media,                    name: t.media_end,                  labelClass: 'label-important'},
        {id: 62, stateName: t.media,                    name: t.media,                      labelClass: 'label-important'},

        {id: 63, stateName: t.video_loss,               name: t.video_loss,                 labelClass: 'label-important', color: '#5382B9'},
        {id: 64, stateName: t.lens_covered,             name: t.lens_covered,               labelClass: 'label-important', color: '#5382B9'},
        {id: 65, stateName: t.storage_malfunction,      name: t.storage_malfunction,        labelClass: 'label-important', color: '#5382B9'},
        {id: 67, stateName: t.driver_fatigue,           name: t.driver_fatigue,             labelClass: 'label-important', color: '#5382B9'},
        {id: 68, stateName: t.no_driver,                name: t.no_driver,                  labelClass: 'label-important', color: '#5382B9'},
        {id: 69, stateName: t.phone_calling,            name: t.phone_calling,              labelClass: 'label-important', color: '#5382B9'},
        {id: 70, stateName: t.driver_smoking,           name: t.driver_smoking,             labelClass: 'label-important', color: '#5382B9'},
        {id: 71, stateName: t.driver_distracted,        name: t.driver_distracted,          labelClass: 'label-important', color: '#5382B9'},
        {id: 72, stateName: t.lane_departure_warning,   name: t.lane_departure_warning,     labelClass: 'label-important', color: '#5382B9'},
        {id: 73, stateName: t.forward_collision_warning, name: t.forward_collision_warning, labelClass: 'label-important', color: '#5382B9'},
        {id: 74, stateName: t.headway_monitoring_warning, name: t.headway_monitoring_warning, labelClass: 'label-important', color: '#5382B9'},
        {id: 75, stateName: t.yawn,                     name: t.yawn,                       labelClass: 'label-important', color: '#5382B9'},
        {id: 77, stateName: t.excessive_speed,          name: t.excessive_speed,            labelClass: 'label-important', color: '#5382B9'},
        {id: 78, stateName: t.disobey_stop_sign,        name: t.disobey_stop_sign,          labelClass: 'label-important', color: '#5382B9'},
        {id: 79, stateName: t.power_failure_alarm,      name: t.power_failure_alarm,        labelClass: 'label-important', color: '#5382B9'},
        {id: 80, stateName: t.working,                  name: t.working_start,              labelClass: 'label-important', color: '#006400'},
        {id: 81, stateName: t.working,                  name: t.working_end,                labelClass: 'label-important', color: '#9E9D24'},
        {id: 82, stateName: t.commuting,                name: t.commuting_start,            labelClass: 'label-important', color: '#1D7CDD'},
        {id: 83, stateName: t.commuting,                name: t.commuting_end,              labelClass: 'label-important', color: '#4FC3F7'},
        {id: 84, stateName: t.requested_video,          name: t.requested_video,            labelClass: 'label-important', color: '#5382B9'},
        {id: 85, stateName: t.tire_pressure,            name: t.tire_pressure_out_of_range, labelClass: 'label-danger',    color: '#673AB7'},
        {id: 86, stateName: t.tire_pressure,            name: t.tire_pressure_normalized,   labelClass: 'label-success',   color: '#B39DDB'},
        {id: 87, stateName: t.forward_vehicle_start_alert, name: t.forward_vehicle_start_alert, labelClass: 'label-important', color: '#5382B9'},
        {id: 88, stateName: t.forward_proximity_warning, name: t.forward_proximity_warning, labelClass: 'label-important', color: '#5382B9'},
        {id: 92, stateName: t.speed_limit_sign_violation, name: t.speed_limit_sign_violation, labelClass: 'label-important', color: '#5382B9'},
        {id: 93, stateName: t.asset_connection,         name: t.asset_detected,             labelClass: 'label-success',   color: '#0E3E79'},
        {id: 94, stateName: t.asset_connection,         name: t.asset_lost,                 labelClass: 'label-danger',    color: '#6A5ACD'},

        {id: 95, stateName: t.lock.rope_cut,            name: t.lock.rope_cut,              labelClass: 'label-danger',     color: '#B21319'},
        {id: 96, stateName: t.lock.locked,              name: t.lock.locked,                labelClass: 'label-danger',     color: '#B21319'},
        {id: 97, stateName: t.lock.unlocked,            name: t.lock.unlocked,              labelClass: 'label-success',    color: '#42B555'},
        {id: 98, stateName: t.lock.lock_error,          name: t.lock.lock_error,            labelClass: 'label-danger',     color: '#B21319'},
        {id: 99, stateName: t.lock.unlock_error,        name: t.lock.unlock_error,          labelClass: 'label-danger',     color: '#B21319'},

        {id: 100, stateName: t.seat_belt_unbuckled,     name: t.seat_belt_unbuckled,        labelClass: 'label-danger',    color: '#6D0C03'}, // AI Seat Belt

        {id: 101, stateName: t.low_battery_voltage,     name: t.low_battery_voltage,        labelClass: 'label-warning',   color: '#B21319'},
        {id: 102, stateName: t.low_battery_voltage,     name: t.battery_voltage_normalized, labelClass: 'label-success',   color: '#FF9C6E'},
        {id: 103, stateName: t.angular_movement,        name: t.angular_movement_start,     labelClass: 'label-info',      color: '#054AAE'},
        {id: 104, stateName: t.angular_movement,        name: t.angular_movement_end,       labelClass: 'label-info',      color: '#7196F2'},
        {id: 105, stateName: t.magnet,                  name: t.magnet_start,               labelClass: 'label-info',      color: '#054AAE'},
        {id: 106, stateName: t.magnet,                  name: t.magnet_end,                 labelClass: 'label-info',      color: '#7196F2'},
        {id: 107, stateName: t.target_device_lost,      name: t.target_device_lost,         labelClass: 'label-danger',    color: '#B21319'},
        {id: 108, stateName: t.target_device_lost,      name: t.target_device_found,        labelClass: 'label-success',   color: '#FF9C6E'},
        {id: 109, stateName: t.pedestrian_collision_warning, name: t.pedestrian_collision_warning, labelClass: 'label-important', color: '#5382B9'},
        {id: 110, stateName: t.turbo_timer_violated,    name: t.turbo_timer_violated,       labelClass: 'label-danger',    color: '#E82228'},
    ];
    //@formatter:on

    var EventTypesController = Positrace.Object.extend({

        //@formatter:off
        TYPES: {
            START:      [1, 5, 7, 8, 15, 41, 43, 55, 85],
            END:        [2, 6, 17, 9, 16, 42, 44, 56, 86],
            FUEL:       [41, 42, 43, 44],
            PRESSURE:   [85, 86], PRESSURE_OUT: 85, PRESSURE_NORMALIZED: 86,
            TEMPERATURE:[35, 36], TEMPERATURE_OUT: 35, TEMPERATURE_NORMALIZED: 36,
            ALARM:      18,
            IGNITION:   [1, 2],     IGNITION_ON:     1,    IGNITION_OFF:  2,
            SPEEDING:   [5, 6],     SPEEDING_START:  5,    SPEEDING_END:  6,
            TOWING:     [7, 17],    TOWING_START:    7,    TOWING_END:    17,
            IDLING:     [8, 9],     IDLING_START:    8,    IDLING_END:    9,
            PTO:        [15, 16],   PTO_ON:          15,   PTO_OFF:       16,
            FILL_UP:    [41, 42],   FILL_UP_START:   41,   FILL_UP_END:   42,
            DRAIN:      [43, 44],   DRAIN_START:     43,   DRAIN_END:     44,
            LOW_VOLTAGE:[55, 56],   LOW_VOLTAGE_START: 55, LOW_VOLTAGE_END: 56,
            SEAT_BELT_BUCKLING: [49, 50], SEAT_BELT_UNBUCKLED: 49, SEAT_BELT_BUCKLED: 50,
            WORKING:    [80, 81],   WORKING_START: 80,     WORKING_END: 81,
            COMMUTING:  [82, 83],   COMMUTING_START: 82,   COMMUTING_END: 83,
            ASSET_CONNECTION: [93, 94], ASSET_DETECTED: 93, ASSET_LOST: 94,
            MEDIA:      [60, 61, 62],   MEDIA_START: 60,       MEDIA_END: 61,     MEDIA_INSTANT: 62,
            ANGULAR_MOVEMENT: [103, 104], ANGULAR_MOVEMENT_START: 103, ANGULAR_MOVEMENT_END: 104,
            MAGNET:     [105, 106], MAGNET_ON: 105,        MAGNET_OFF: 106,
            TARGET_DEVICE: [107, 108], TARGET_DEVICE_LOST: 107, TARGET_DEVICE_FOUND: 108, 
            PEDESTRIAN_COLLISION_WARNING: 109, TURBO_TIMER_VIOLATED: 110
        },
        //@formatter:on

        initialize: function () {
            this.collection = new Positrace.locate.collection.EventTypesCollection(eventTypesInitialData);
            this.listenTo(this.collection, 'change', function () {
                this.trigger('change');
                this.saveCollection();
            });
        },

        restoreCollection: function () {
            var collection = this.collection;
            return LocateApp.remember('collection-event-types')
                .then(function (eventsTypesJSON) {
                    collection.set(JSON.parse(eventsTypesJSON), {add: false, remove: false, silent: true});
                });
        },

        saveCollection: function () {
            var reducedCollection = this.collection.map(function (eventType) {
                return {
                    id: eventType.id,
                    hidden: !!eventType.get('hidden')
                }
            });
            LocateApp.remember('collection-event-types', JSON.stringify(reducedCollection));
        },

        getName: function (eventTypeId) {
            var e = this.collection.get(eventTypeId);
            if (e) return e.get('name');
        },

        getStateName: function (eventTypeId) {
            var e = this.collection.get(eventTypeId);
            if (e) return e.get('stateName');
        },

        getLabelClass: function (eventTypeId) {
            var e = this.collection.get(eventTypeId);
            if (e) return e.get('labelClass');
        },

        isHidden: function (eventTypeId) {
            var e = this.collection.get(eventTypeId);
            if (e) return e.get('hidden');
        },

        getVisibility: function (eventTypeId) {
            var e = this.collection.get(eventTypeId);
            if (e) return e.get('hidden');
        },

        setVisible: function (eventTypeIds) {
            eventTypeIds = _.isArray(eventTypeIds) ? eventTypeIds : [eventTypeIds];
            this.collection.set(_.map(eventTypeIds, function (eventTypeId) {
                return {
                    id: eventTypeId,
                    hidden: false
                }
            }), {add: false, remove: false});
        },

        setInvisible: function (eventTypeIds) {
            eventTypeIds = _.isArray(eventTypeIds) ? eventTypeIds : [eventTypeIds];
            this.collection.set(_.map(eventTypeIds, function (eventTypeId) {
                return {
                    id: eventTypeId,
                    hidden: true
                }
            }), {add: false, remove: false});
        },

        getColor: function (eventTypeId) {
            var e = this.collection.get(eventTypeId),
                color;
            if (e && (color = e.get('color'))) {
                return color;
            }
            return Base.colors.UNKNOWN_EVENT_MARKER_COLOR;
        }

    });

    return new EventTypesController();

});
