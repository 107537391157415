const Positrace = require('positrace');
const GroupDecorator = require('./GroupDecorator');
const equipmentsStorage = require('../../base/EquipmentsStorage').equipmentsStorage;

const AccountDecorator = Positrace.Object.extend({

    allowedEntityNames: ['equipment', 'landmarks', 'geofences'],

    initialize: function (options) {
        this.model = options.model;
    },

    _getDisplaying(entitiesName) {
        if (!this.allowedEntityNames.includes(entitiesName)) {
            throw new Error(`Allowed entities: ${this.allowedEntityNames.join(', ')}`);
        }

        const displayingName = `${entitiesName}Displaying`;

        const model = this.model;
        if (model[displayingName]) {
            return model[displayingName];
        } else {
            model[displayingName] = new Positrace.Model({
                is_visible: true,
                is_collapsed: false
            });
            model[displayingName].on('change:is_visible', (displayingModel, value, options) => {
                model.trigger('change:is_visible', model, value, options);
            });
            model[displayingName].on('change:is_collapsed', (displayingModel, value, options) => {
                model.trigger('change:is_collapsed', model, value, options);
            });

            return model[displayingName];
        }
    },

    getIsCollapsed(entitiesName) {
        return this._getDisplaying(entitiesName).get('is_collapsed');
    },

    setIsCollapsed(entitiesName, value) {
        this._getDisplaying(entitiesName).set('is_collapsed', value);
    },

    setAllIsCollapsed(value) {
        this.allowedEntityNames.forEach(entitiesName => {
            this.setIsCollapsed(entitiesName, value);
        })
    },

    toggleIsCollapsed(entitiesName) {
        const newIsCollapsed = !this.getIsCollapsed(entitiesName);
        this.setIsCollapsed(entitiesName, newIsCollapsed);
        if (!newIsCollapsed) {
            this.setIsVisible(entitiesName, true);
        }
    },

    getIsVisible(entitiesName) {
        return this._getDisplaying(entitiesName).get('is_visible');
    },

    setIsVisible(entitiesName, value) {
        this._getDisplaying(entitiesName).set('is_visible', value);

        _.each(this._getGroups(entitiesName), group => {
            let groupDecorator = GroupDecorator.decorate(group);
            groupDecorator.setIsVisible(value);
        });

        _.each(this._getEntities(entitiesName), entity => {
            if (entity.displaying) {
                entity.displaying.set('is_visible', value);
            }
        });
    },

    setAllIsVisible(value) {
        this.allowedEntityNames.forEach(entitiesName => {
            this.setIsVisible(entitiesName, value);
        })
    },

    toggleIsVisible: function (entitiesName) {
        const newIsVisible = !this.getIsVisible(entitiesName);
        this.setIsVisible(entitiesName, newIsVisible);
        this.setIsCollapsed(entitiesName, !newIsVisible);
    },

    _getChildrenFromCollection: function (childrenCollection, options) {
        options = _.extend({includeHidden: false}, options);
        var model = this.model;

        return childrenCollection.filter(function (child) {
            if (!options.includeHidden && !child.isShowOnMap()) {
                return false;
            }
            return child.get('account_id') === model.id;
        });
    },

    getGroups: function () {
        return LocateApp.request('collection:groups').where({account_id: this.model.id});
    },

    _getGroups(entitiesName) {
        switch (entitiesName) {
            case 'equipment':
                return this.getEquipmentGroups();
            case 'landmarks':
                return this.getLandmarkGroups();
            case 'geofences':
                return this.getGeofenceGroups();
        }
    },

    _getEntities(entitiesName) {
        switch (entitiesName) {
            case 'equipment':
                return this.getEquipments();
            case 'geofences':
                return this.getGeofences();
            case 'landmarks':
                return this.getLandmarks();
        }
    },

    getEquipmentGroups: function () {
        return this.getGroups().filter(function (groupModel) {
            return groupModel.isEquipmentGroup();
        });
    },

    getGeofenceGroups: function () {
        return this.getGroups().filter(function (groupModel) {
            return groupModel.isGeofenceGroup();
        });
    },

    getLandmarkGroups: function () {
        return this.getGroups().filter(function (groupModel) {
            return groupModel.isLandmarkGroup();
        });
    },

    getEquipments: function (options) {
        return this._getChildrenFromCollection(equipmentsStorage.getCollection(), options);
    },

    getGeofences: function (options) {
        return this._getChildrenFromCollection(LocateApp.request('collection:geofences'), options);
    },

    getLandmarks: function (options) {
        return this._getChildrenFromCollection(LocateApp.request('collection:landmarks'), options);
    }

});

AccountDecorator.decorate = function (model) {
    if (!model.decorator) {
        model.decorator = new AccountDecorator({
            model: model
        });
    }
    return model.decorator;
};

module.exports = AccountDecorator;
