import "core-js/stable";
import "regenerator-runtime/runtime";

import Application from '../auth/Application';
import '../base';
import 'browser-support';

var app = window.LocateApp = new Application();

$(document).ready(function () {
    app.start();
});
