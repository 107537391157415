const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let clusterIconsCache = {};

export default {
    getClusterURL(color) {
        let cacheKey = `${color}`;
        let url = clusterIconsCache[cacheKey];
        if (!url) {
            url = clusterIconsCache[cacheKey] = URL_PREFIX + btoa(this.getClusterSVG(color));
        }
        return url;
    },

    getClusterSVG(color) {
        const _color = color || '#FFC100';

        return `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
                <defs>
                    <g id="a" transform="rotate(105)">
                        <path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.6"/>
                        <path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.4"/>
                        <path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.2"/>
                    </g>
                </defs>
                <g fill="${_color}">
                    <circle r="42"/>
                    <use xlink:href="#a"/>
                    <g transform="rotate(120)">
                        <use xlink:href="#a"/>
                    </g>
                    <g transform="rotate(240)">
                        <use xlink:href="#a"/>
                    </g>
                </g>
            </svg>
        `;
    },
}
