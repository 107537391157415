define(['positrace'], function (Positrace) {

    var EquipmentDecorator = Positrace.Object.extend({

        initialize: function (options) {
            return this.model = options.model;
        },

        _getDisplaying() {
            const model = this.model;
            if (model.displaying) {
                return model.displaying;
            } else {
                model.displaying = new Positrace.Model({
                    is_visible: true
                });
                model.displaying.on('change:is_visible', function (displayingModel, value, options) {
                    model.trigger('change:is_visible', model, value, options);
                });

                return model.displaying;
            }
        },

        getIsVisible() {
            return this._getDisplaying().get('is_visible');
        },

        setIsVisible(value) {
            this._getDisplaying().set('is_visible', value);
        },

        getPosition: function () {
            let coordinate = this.model.getCoordinate();
            if (coordinate) {
                return Base.geography.createLatLng(coordinate);
            }
        },

        getLabelCssClass: function () {
            return 'markerLabels-' + this.model.getMarkerState();
        },

        getGroupName: function () {
            return this.model.group.getName();
        },

        getIcon: function () {
            let color = this.model.getHEXColor();
            return Base.icons.getEquipmentURL(color, this.getType());
        },

        getMapIcon: function (options) {
            let color = this.model.getHEXColor();
            let state = this.model.getMarkerState();
            let hasAsset = options?.withAssetIndicator ? this.hasAssignedAssets() : undefined;
            return Base.icons.getMapEquipmentIcon(color, this.getType(), state, hasAsset);
        },

        getSmallImage() {
            let iconPath = this.getIcon();
            return `<img height='18px' alt='' src='${iconPath}'/>`;
        },

        getLargeImage() {
            let iconPath = this.getIcon();
            return `<img height='33px' alt='' src='${iconPath}'/>`;
        },

        getMessageCount: function () {
            return this.model.get('unread_messages_count') || 0;
        },

        hasMediaDevice: function () {
            return !!this.model.get('media_device');
        },

        canCreateLiveStreamingSession() {
            return _.can('create.Devices::Media::LiveStreamingSessions')
                && (
                    this.model.hasFeature('equ.media.live_video')
                    || this.model.hasFeature('equ.media.live_video_qlcv200')
                    || this.model.hasFeature('equ.media.live_video_xirgocam')
                    || this.model.hasFeature('equ.media.live_snapshots')
                )
                && this.hasMediaDevice();
        },

        canCreateLiveVideoSession() {
            return _.can('create.Devices::Media::LiveStreamingSessions')
                && (
                    this.model.hasFeature('equ.media.live_video')
                    || this.model.hasFeature('equ.media.live_video_qlcv200')
                    || this.model.hasFeature('equ.media.live_video_xirgocam')
                )
                && this.hasMediaDevice();
        },

        canCreateLiveSnapshotsSession() {
            return _.can('create.Devices::Media::LiveStreamingSessions')
                && this.model.hasFeature('equ.media.live_snapshots')
                && this.hasMediaDevice();
        },

        getName: function () {
            return this.model.get('name');
        },

        getType() {
            let equipmentType = this.model.get('equipment_type');
            return equipmentType && equipmentType.symbol || null;
        },

        getDeviceStatusName: function () {
            return _.t(this.model.status.getLabel().name);
        },

        isOnline: function () {
            return !!this.model.isOnline();
        },

        hasGps: function () {
            return !!this.model.state.gps.isValid();
        },

        isPinned() {
            return !!this.model.get('pinned');
        },

        hasHardwareAlertsAvailable: function () {
            return this.model.get('hardware_alerts_available');
        },

        getOneWireTemperatureSensors() {
            return (this.model.get('equipment_temperature_sensors') || [])
                .filter(sensor => sensor.type === 'one_wire');
        },

        getTireTemperatureSensors() {
            return (this.model.get('equipment_temperature_sensors') || [])
                .filter(sensor => sensor.type === 'tire');
        },

        getTirePressureSensors() {
            return (this.model.get('equipment_tire_pressure_sensors') || []);
        },

        IGNITION_ON: 'On',

        IGNITION_OFF: 'Off',

        isIgnitionOn: function () {
            return this.model.state.isIgnition();
        },

        isIgnitionOff: function () {
            return !this.model.state.isIgnition();
        },

        getIgnition: function () {
            return this.model.state.isIgnition();
        },

        hasAssignedAssets() {
            return !!this.model.state.get('equipment_asset_ids')?.length;
        },

        getAssignedAssets() {
            const assetIds = this.model.state.get('equipment_asset_ids');

            if (assetIds?.length) {
                let assetsCollection = LocateApp.request('collection:assets');
                return assetsCollection.filter((asset) => _.contains(assetIds, asset.id));
            }
        }

    });

    EquipmentDecorator.decorate = function (model) {
        if (!model.decorator) {
            model.decorator = new EquipmentDecorator({
                model: model
            });
        }
        return model.decorator;
    };

    return EquipmentDecorator;
});
