define([
    'positrace'
], function (Positrace) {

    var UrlBuilder = Positrace.UrlBuilder.extend({

        setConfig: function (options) {
            Positrace.UrlBuilder.prototype.setConfig.apply(this, arguments);
            this.locale = options.locale
        },

        localize: function (path) {
            path = this.trimPath(path);
            return "/" + this.locale + "/" + path;
        },

    });

    return UrlBuilder;
});