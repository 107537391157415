define([
    'positrace'
], function (Positrace) {

    //@formatter:off
    var geoColorsData = [
        {id: 1,  name: "red",          rgb: "#ff0000"},
        {id: 2,  name: "blue",         rgb: "#0000FF"},
        {id: 3,  name: "green",        rgb: "#4CAF50"},
        {id: 4,  name: "orange",       rgb: "#FF9800"},
        {id: 5,  name: "pink",         rgb: "#f93678"},
        {id: 6,  name: "purple",       rgb: "#9C27B0"},
        {id: 7,  name: "brown",        rgb: "#795548"},
        {id: 8,  name: "cyan",         rgb: "#00BCD4", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 9,  name: "dark blue",    rgb: "#000080"},
        {id: 10, name: "dark green",   rgb: "#006400"},
        {id: 11, name: "light green",  rgb: "#9cf644", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 12, name: "light orange", rgb: "#FFC107", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 13, name: "light yellow", rgb: "#ffe500", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 14, name: "lilac",        rgb: "#EE82EE", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 15, name: "yellow",       rgb: "#FFD700", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 16, name: "teal",         rgb: "#009688"},
        {id: 17, name: "dark red",     rgb: "#8B0000"},
        {id: 18, name: "magenta",      rgb: "#FF00FF"},
        {id: 19, name: "light purple", rgb: "#B39DDB"},
        {id: 20, name: "deep purple",  rgb: "#673AB7"},
        {id: 21, name: "slate blue",   rgb: "#6a5acd"},
        {id: 22, name: "dark lime",    rgb: "#9E9D24"},
        {id: 23, name: "deep orange",  rgb: "#fd4d16"},
        {id: 24, name: "light brown",  rgb: "#CD853F"},
        {id: 25, name: "rosy brown",   rgb: "#BC8F8F"},
        {id: 26, name: "misty rose",   rgb: "#92506C"},
        {id: 27, name: "blue grey",    rgb: "#607D8B"},
        {id: 28, name: "light blue",   rgb: "#4FC3F7", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 29, name: "aqua",         rgb: "#00FFFF", contrastColor: "rgba(0,0,0,0.8)"},
        {id: 30, name: "aquamarine",   rgb: "#7fffd4", contrastColor: "rgba(0,0,0,0.8)"}
    ];
    //@formatter:on

    var ColorsController = Positrace.Object.extend({

        UNKNOWN_EVENT_MARKER_COLOR: '#32A0F3',
        EVENTS_GROUP_MARKER_COLOR: '#5382B9',
        TRIP_COLOR: 'blue',
        NON_TRIP_COLOR: 'red',
        GEOFENCE_EDITOR_COLOR: 'red',
        TOWNSHIP_COLOR: 'red',

        initialize: function () {
            this.collection = new Positrace.Collection(geoColorsData);
        },

        getName: function (colorId) {
            var e = this.collection.get(colorId);
            if (e) return _.t(e.get('name'));
        },

        getContrastColor(originColor) {
            let colorModel = this.collection.findWhere({rgb: originColor});
            let defaultContrastColor = "#FFFFFF";
            return colorModel && colorModel.get('contrastColor') || defaultContrastColor;
        },

        getOptionsForSelect: function (entity) {
            var iconConstructor = null;
            switch (entity) {
                case 'landmark':
                    iconConstructor = _.bind(Base.icons.getLandmarkURL, Base.icons);
                    break;
                case 'geofence':
                    iconConstructor = _.bind(Base.icons.getGeofenceURL, Base.icons);
                    break;
                default:
                    iconConstructor = _.bind(Base.icons.getLandmarkURL, Base.icons);
            }
            return this.collection.map(function (val) {
                var template = _.template('<option value="{{ id }}" data-content="<img style=\'height:18px;\' src=\'{{ iconPath }}\'/>{{ name }}">{{ name }}</option>');
                return template({
                    id: val.get('rgb'),
                    iconPath: iconConstructor(val.get('rgb')),
                    name: this.getName(val.id)
                });
            }, this).join('');
        }

    });

    return new ColorsController();

});
