import Positrace from 'positrace';

import {equipmentsStorage} from '../EquipmentsStorage';

export default class AssetDecorator {

    static decorate(model) {
        if (!model.decorator) {
            model.decorator = new AssetDecorator({
                model: model
            });
        }

        return model.decorator;
    }

    constructor({model}) {
        this.model = model;
    }

    _getDisplaying() {
        const model = this.model;
        if (model.displaying) {
            return model.displaying;
        } else {
            model.displaying = new Positrace.Model({
                is_visible: true
            });
            model.displaying.on('change:is_visible', function (displayingModel, value, options) {
                model.trigger('change:is_visible', model, value, options);
            });

            return model.displaying;
        }
    }

    isAssignedToEquipment() {
        return this.model.state.get('asset_assigned');
    }

    getDurationFromLastDetection() {
        return +new Date - this.model.state.getReceiptTime();
    }

    getAssignedEquipment() {
        const equipmentId = this.model.state.get('equipment_id');

        if (equipmentId) {
            let equipmentCollection = equipmentsStorage.getCollection();
            return equipmentCollection.get(equipmentId);
        }
    }

    getIsVisible() {
        return this._getDisplaying().get('is_visible');
    }

    setIsVisible(value) {
        this._getDisplaying().set('is_visible', value);
    }

    getTypeSymbol() {
        return this.model.get('asset_type')?.symbol;
    }

    getIcon() {
        return Base.icons.getAssetURL(this.model.getHEXColor(), this.getTypeSymbol());
    }

    getMapIcon() {
        let color = this.model.getHEXColor();
        return Base.icons.getMapAssetIcon(color, this.getTypeSymbol());
    }

    getSmallImage() {
        let iconPath = this.getIcon();
        return `<img height='18px' alt='' src='${iconPath}'/>`;
    }

    getLargeImage() {
        let iconPath = this.getIcon();
        return `<img height='33px' alt='' src='${iconPath}'/>`;
    }

    isShowOnMap() {
        return this.model.isShowOnMap();
    }

    getPosition() {
        let coordinate = this.model.getCoordinate();
        if (coordinate) {
            return Base.geography.createLatLng(coordinate);
        }
    }

    getName() {
        return this.model.get("name") || '';
    }

    getLabelCssClass() {
        return 'map-label';
    }

    lat() {
        return this.model.get('latitude');
    }

    lng() {
        return this.model.get('longitude');
    }

}
